import { Api } from '@/services/API/Api'
import { Group } from '@/models/Group'
import { GroupEvent } from '@/models/GroupEvent'
import { GroupEventJob } from '@/models/GroupEventJob'
import { ClubMember } from '@/models/ClubMember'

class GroupEventJobService {
  private api: Api

  constructor () {
    this.api = new Api()
  }

  public async addJob (clubMember:ClubMember, teamEvent:GroupEvent, title:string, description:string): Promise<GroupEventJob|undefined> {
    const response = await this.api.request('event/' + teamEvent.id + '/job/create', 'POST', {
      clubMemberId: clubMember?.id,
      title: title,
      description: description
    })
    if (response.ok) {
      return await response.json()
    }
  }

  public async saveJob (job:GroupEventJob): Promise<GroupEventJob|undefined> {
    const response = await this.api.request('eventJob/' + job.id, 'POST', {
      clubMemberId: job.club_member?.id,
      title: job.title,
      description: job.description
    })
    if (response.ok) {
      return await response.json()
    }
  }

  public async takeJob (job:GroupEventJob): Promise<GroupEventJob|undefined> {
    const response = await this.api.request('eventJob/' + job.id + '/take', 'POST')
    if (response.ok) {
      return await response.json()
    }
  }

  public async AcceptJob (Job:GroupEventJob): Promise<GroupEventJob|undefined> {
    const response = await this.api.request('eventJob/' + Job.id + '/accept', 'POST')
    if (response.ok) {
      return await response.json()
    }
  }

  public async DeclineJob (Job:GroupEventJob): Promise<GroupEventJob|undefined> {
    const response = await this.api.request('eventJob/' + Job.id + '/decline', 'POST')
    if (response.ok) {
      return await response.json()
    }
  }

  public async getOpenUpcomingJobs (team:Group): Promise<GroupEventJob[]|undefined> {
    const response = await this.api.request('group/' + team.id + '/jobs/open', 'GET')
    if (response.ok) {
      return await response.json()
    }
  }

  public async DeleteJob (Job:GroupEventJob) : Promise<GroupEventJob|undefined> {
    const response = await this.api.request('eventJob/' + Job.id, 'DELETE')
    if (response.ok) {
      return await response.json()
    }
  }

  public async CopyJob (Job:GroupEventJob) : Promise<GroupEventJob|undefined> {
    const response = await this.api.request('eventJob/' + Job.id + '/copy', 'POST')
    if (response.ok) {
      return await response.json()
    }
  }
}

export default GroupEventJobService
