<template>
  <Dashboard>
    <v-breadcrumbs>
      <v-breadcrumbs-item :to="{name: 'ClubDashboard'}" exact>
        <ClubLogo></ClubLogo>
      </v-breadcrumbs-item>
      <v-icon>mdi-slash-forward</v-icon>
      <v-breadcrumbs-item :to="{name: 'Members'}" exact>
        {{$t('groupMembers.members')}}
      </v-breadcrumbs-item>
      <v-icon>mdi-slash-forward</v-icon>
      <v-breadcrumbs-item :to="{name: 'MemberDuplicates'}">
        Duplicates
      </v-breadcrumbs-item>
    </v-breadcrumbs>
    <ClubMemberDuplicatesTables></ClubMemberDuplicatesTables>
  </Dashboard>
</template>

<script>
import Dashboard from '../../../components/Main/Dashboard'
import ClubLogo from '@/components/Club/ClubLogo'
import ClubMemberDuplicatesTables from '@/components/Club/Member/ClubMemberDuplicatesTables'
export default {
  name: 'Duplicates',
  components: { ClubMemberDuplicatesTables, ClubLogo, Dashboard }
}
</script>

<style scoped>

</style>
