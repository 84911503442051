
import ClubMemberService from '@/services/Enitities/ClubMemberService'

export default {
  name: 'MemberRemove',
  props: {
    clubMember: {
      required: true
    }
  },
  data ():unknown {
    return {
      dialog: false
    }
  },
  methods: {
    async removeMember ():Promise<void> {
      this.$store.commit('toggleLoader')
      await (new ClubMemberService()).removeMember(this.clubMember)
      this.$store.commit('toggleLoader')
    }
  }
}
