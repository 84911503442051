
import { GroupMemberTypes } from '@/models/Enum'
import moment from 'moment'

export default {
  name: 'ClubMemberRegistration',
  props: {
    clubMember: {
      required: true
    }
  },
  data ():unknown {
    return {
      moment,
      GroupMemberTypes
    }
  }
}
