
import ClubMemberService from '@/services/Enitities/ClubMemberService'
import EventJobsItem from '@/components/Event/EventJobs/EventJobsItem.vue'

export default {
  name: 'MemberTasks',
  components: { EventJobsItem },
  props: {
    clubMember: {
      required: true
    },
    count: {
      required: true
    }
  },
  data ():unknown {
    return {
      dialog: false,
      loading: false,
      tasks: []
    }
  },
  methods: {
    async loadTasks ():Promise<void> {
      this.loading = true
      this.tasks = await (new ClubMemberService().getMemberTasks(this.clubMember.id))
      this.loading = false
    }
  }
}
