
import Application from '@/services/App/Application'

export default {
  name: 'MainActionCard',
  props: {
    title: {
      required: true
    },
    icon: {
      type: String,
      default: 'mdi-checkbox-blank'
    },
    to: {
      default: ():unknown => { return {} }
    },
    value: {
      default: 0
    },
    dialog: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: 'dialog'
  },
  data ():unknown {
    return {
      Application,
      childDialog: this.dialog
    }
  },
  watch: {
    childDialog ():void {
      this.$emit('input', this.childDialog)
    },
    dialog ():void {
      this.childDialog = this.dialog
    }
  },
  methods: {
    gotToSource ():void {
      this.$emit('click')
      if ('name' in this.to) {
        this.$router.push(this.to)
      } else {
        this.childDialog = true
      }
    }
  }
}
