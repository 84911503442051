
import GroupEventJobService from '@/services/Enitities/GroupEventJobService'
import SearchClubMember from '@/components/Member/SearchClubMember.vue'

export default {
  name: 'EventJobCreateDialog',
  components: { SearchClubMember },
  props: {
    groupEvent: {
      required: true
    },
    group: {
      required: true
    }
  },
  data ():unknown {
    return {
      dialog: false,
      valid: false,
      selectedMember: null,
      groupMembers: [],
      title: '',
      titleRules: [
        (v):boolean|string => !!v || this.$t('requiredField'),
        (v):boolean|string => v.length < 255 || 'Title needs to be smaller then 255 characters'
      ],
      description: ''
    }
  },
  methods: {
    validate ():void {
      this.$refs.form.validate()
    },
    async createJob (e:Event):Promise<void> {
      e.preventDefault()
      this.validate()
      if (this.valid) {
        this.$store.commit('toggleLoader')
        const job = await (new GroupEventJobService()).addJob(this.selectedMember, this.groupEvent, this.title, this.description)
        this.$store.commit('toggleLoader')
        if (job) {
          this.$store.commit('addMessage', {
            text: 'Job created'
          })
          this.groupEvent.group_event_jobs.push(job)
          this.dialog = false
        }
      }
    }
  }
}
