import { Api } from '@/services/API/Api'
import { Group } from '@/models/Group'
import { GroupMember } from '@/models/GroupMember'
import { ClubMember } from '@/models/ClubMember'

export class GroupMemberService {
  api:Api
  constructor () {
    this.api = new Api()
  }

  public async addMembers (team:Group, clubMember:ClubMember, type:number, rights:[]):Promise<Response> {
    return await this.api.request('group/' + team.id + '/member/add', 'POST', {
      ClubMemberId: clubMember.id,
      type: type,
      rights: rights
    })
  }

  public async saveMember (team:Group, member:GroupMember):Promise<Response> {
    return await this.api.request('group/' + team.id + '/member/' + member.id, 'POST', {
      rights: member.rights,
      type: member.type
    })
  }

  public async getMembers (team:Group, startDate = '', endDate = ''):Promise<GroupMember[]> {
    const response = await this.api.request('group/' + team.id + '/member/', 'GET', {
      startDate: startDate,
      endDate: endDate
    })
    return await response.json() as GroupMember[]
  }

  public async deleteMember (team:Group, teamMember:GroupMember):Promise<Response> {
    return await this.api.request('group/' + team.id + '/member/' + teamMember.id, 'DELETE')
  }
}

export default GroupMemberService
