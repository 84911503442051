
import { MemberNotification } from '@/models/MemberNotification'
import NotificationService from '@/services/Enitities/NotificationService'
import ClubMemberService from '@/services/Enitities/ClubMemberService'

export default {
  name: 'LatestNotification',
  data ():unknown {
    return {
      selectedMember: ClubMemberService.getCurrentMember(),
      notification: this.$store.state.notifications.filter(n => !n.is_seen).sort((a, b) => (a.created_at > b.created_at) ? 1 : -1)[0]
    }
  },
  methods: {
    async goTo (notification: MemberNotification):Promise<void> {
      const response = await (new NotificationService()).notificationIsSeen(notification)
      if (response) {
        this.$store.commit('setNotifications', NotificationService.getAllLocalNotifications())
        if (this.selectedMember.id !== notification.club_member.id) {
          ClubMemberService.setCurrentMember(notification.club_member)
        }
        await this.$router.push(notification.url)
      }
    }
  }
}
