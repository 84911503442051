
import { Http } from '@/services/API/Http'
import EventCard from '@/components/Event/EventDialog/EventCard.vue'
import Application from '@/services/App/Application'

export default {
  name: 'GroupEvents',
  components: { EventCard },
  data ():unknown {
    return {
      loading: false,
      groupEvents: [],
      currentPage: 1,
      pageAmount: 3
    }
  },
  watch: {
    async $route ():Promise<void> {
      await this.getEvents(this.$route.params.id)
    }
  },
  async created ():Promise<void> {
    await this.getEvents(this.$route.params.id)
  },
  methods: {
    async getEvents (id:string):Promise<void> {
      this.loading = true
      const amountPP = 15
      const http = new Http(Application.getApiUrl() + '/api/v1/group/' + id + '/event', { limit: amountPP, offset: (this.currentPage - 1) * amountPP, order: 'ASC' }, 'GET')
      const response = await http.send()
      if (response.ok) {
        const outcome = await response.json()
        this.groupEvents = outcome.events
        this.totalEvents = outcome.amount
        this.pageAmount = Math.ceil(parseInt(this.totalEvents) / amountPP)
      }
      this.loading = false
    }
  }
}
