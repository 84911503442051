
export default {
  name: 'PwaPrompt',
  data ():unknown {
    return {
      PwaDialog: false,
      IOsDialog: true,
      deferredPrompt: {}
    }
  },
  created ():void {
    this.$on('canInstall', (event) => {
      if (this.isMobile()) {
        event.preventDefault()
        // Stash the event so it can be triggered later:
        this.PwaDialog = true
        this.deferredPrompt = event
      }
    })
  },
  methods: {
    isIos ():boolean {
      const userAgent = window.navigator.userAgent.toLowerCase()
      return /iphone|ipad|ipod/.test(userAgent)
    },
    isInStandaloneMode ():boolean {
      // For iOS
      if ('standalone' in window.navigator) return true

      // For Android
      if (window.matchMedia('(display-mode: standalone)').matches) return true

      // If neither is true, it's not installed
      return false
    },
    isMobile ():boolean {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    },
    promptInstall ():void {
      // Show the prompt:
      this.deferredPrompt.prompt()

      // Wait for the user to respond to the prompt:
      this.deferredPrompt.userChoice.then(() => {
        this.deferredPrompt = null
        this.PwaDialog = false
      })
    }
  }
}
