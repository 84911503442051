import { Http } from './Http'

import Authorization from '@/services/App/Authorization'
import ClubMemberService from '@/services/Enitities/ClubMemberService'
import ClubService from '@/services/Enitities/ClubService'
import { Club } from '@/models/Club'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Cookies from 'js-cookie'
import Application from '@/services/App/Application'
export class Api {
  public async request (endpoint:string, method = 'GET', body = {}): Promise<Response> {
    const headers = new Headers()
    if (Authorization.HasClubMemberLoggedIn()) {
      const member = ClubMemberService.getCurrentMember()
      if (member) {
        headers.set('X-Club-Member', member.id)
      }
    }
    const club = ClubService.get() as Club
    if (club) {
      headers.set('X-Club', club.id)
    }

    const Auth = Cookies.get('Device_AUTH')
    if (Auth) {
      const AuthObj = JSON.parse(Auth)
      headers.set('X-Auth-Id', AuthObj.id)
      headers.set('X-Auth-Token', AuthObj.token)
    }

    const http = new Http(Application.getApiUrl() + '/api/v1/' + endpoint, body, method, headers)
    return await http.send()
  }
}
