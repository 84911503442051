import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import VueI18n from 'vue-i18n'
import { VuePwaInstallPlugin } from 'vue-pwa-install'
import eng from './languages/en'
import nl from './languages/nl'
import VueApexCharts from 'vue-apexcharts'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VueOffline from 'vue-offline' // No typescript support.
import { GroupEventInvite } from '@/models/GroupEventInvite'
import moment from 'moment'
import authentication from '@/services/App/Authentication'
import { WebPush } from '@/services/App/WebPush'
import { MemberNotification } from '@/models/MemberNotification'
import NotificationService from '@/services/Enitities/NotificationService'
import Application from '@/services/App/Application'
import VTiptap from '@peepi/vuetify-tiptap'

Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(VueOffline)
Vue.use(VueI18n)
Vue.use(VuePwaInstallPlugin)
Vue.use(VTiptap)
Vue.use(VueApexCharts)

type State = {
  loader: boolean,
  eventInvites: Record<string, GroupEventInvite>,
  messages: Array<{color?:string, state?:boolean, text:string}>,
  notifications: Array<MemberNotification>
}

const store = new Vuex.Store({
  state: <State>{
    loader: false,
    eventInvites: {},
    messages: [],
    notifications: NotificationService.getAllLocalNotifications()
  },
  getters: {
    getEventInvite: (state) => (id:string): GroupEventInvite | null => {
      const result = state.eventInvites[id]
      if (result !== undefined) {
        return result
      }
      return null
    }
  },
  mutations: {
    toggleLoader (state): void {
      state.loader = !state.loader
    },
    setEventInvite (state, invite:GroupEventInvite):void {
      state.eventInvites[invite.id] = invite
    },
    setNotifications (state, notifications: []):void {
      state.notifications = notifications
    },
    addMessage (state, data:{color?:string, state?:boolean, text:string}):void {
      data.state = true
      if (!('color' in data)) {
        data.color = 'green'
      }
      state.messages.push(data)
    }
  }
})
const lang = authentication.getUser().preferred_language === undefined ? 'en' : authentication.getUser().preferred_language
moment.locale(lang)
const i18n = new VueI18n({
  locale: lang,
  messages: {
    en: eng,
    nl: nl
  }
})

let isLoading = false
setInterval(function () {
  if (!isLoading) {
    isLoading = true
    Application.reloadLocalData(store)
      .then(function () {
        isLoading = false
      })
  }
}, 5000)

WebPush.CheckEnabled()
Vue.component('apexchart', VueApexCharts)

new Vue({
  router,
  vuetify,
  i18n,
  store: store,
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  render: h => h(App)
}).$mount('#app')
