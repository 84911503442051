import { render, staticRenderFns } from "./MemberOverview.vue?vue&type=template&id=6a0a1bdb&scoped=true&"
import script from "./MemberOverview.vue?vue&type=script&lang=ts&"
export * from "./MemberOverview.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a0a1bdb",
  null
  
)

export default component.exports