
import GroupNewsService from '@/services/Enitities/GroupNewsService'
import Authorization from '@/services/App/Authorization'
import { GroupMemberRights } from '@/models/Enum'

export default {
  name: 'CreateNewsItem',
  props: {
    group: {
      required: true
    }
  },
  data (): unknown {
    return {
      GroupMemberRights,
      Authorization,
      dialog: false,
      valid: false,
      news: {
        title: '',
        description: ''
      }
    }
  },
  methods: {
    validate ():void {
      this.$refs.form.validate()
    },
    async createEvent (e:Event):Promise<void> {
      e.preventDefault()
      this.validate()
      if (this.valid) {
        this.$store.commit('toggleLoader')
        const response = await (new GroupNewsService()).createNews(this.news.title, this.news.description, this.group)
        await this.$router.push({ name: 'GroupNewsPost', params: { id: this.group.id, newsId: response.id } })
        this.$store.commit('toggleLoader')
      }
    }
  }
}
