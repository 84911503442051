
import GroupEventJobService from '@/services/Enitities/GroupEventJobService'
import SearchClubMember from '@/components/Member/SearchClubMember.vue'

export default {
  name: 'EventJobEditDialog',
  components: { SearchClubMember },
  props: {
    groupEventJob: {
      required: true
    },
    group: {
      required: true
    }
  },
  data ():unknown {
    return {
      dialog: false,
      valid: false,
      selectedMember: this.groupEventJob?.club_member,
      title: this.groupEventJob.title,
      titleRules: [
        (v):boolean|string => !!v || 'Title is required',
        (v):boolean|string => v.length < 255 || 'Title needs to be smaller then 255 characters'
      ],
      description: this.groupEventJob.description
    }
  },
  methods: {
    validate ():void {
      this.$refs.form.validate()
    },
    async saveJob (e:Event):Promise<void> {
      e.preventDefault()
      this.validate()
      if (this.valid) {
        this.$store.commit('toggleLoader')
        this.groupEventJob.title = this.title
        this.groupEventJob.description = this.description
        this.groupEventJob.club_member = {}
        this.groupEventJob.club_member = this.selectedMember
        const response = await (new GroupEventJobService()).saveJob(this.groupEventJob)
        this.$store.commit('toggleLoader')
        if (response) {
          this.$store.commit('addMessage', {
            text: 'Job saved'
          })
          this.groupEventJob.status = response.status
          this.groupEventJob.club_member = response.club_member
          this.dialog = false
        }
      }
    }
  }
}
