
import GroupService from '@/services/Enitities/GroupService'
import { ClubMemberRights, GroupTypes } from '@/models/Enum'
import ClubService from '@/services/Enitities/ClubService'
import Authorization from '@/services/App/Authorization'

export default {
  name: 'CreateNewGroupDialog',
  data ():unknown {
    return {
      valid: false,
      name: '',
      groupType: GroupTypes.Team,
      canCreatClubEvents: false,
      groupTypes: [],
      nameRule: [
        (v):boolean|string => !!v || this.$t('requiredField')
      ],
      Authorization,
      ClubMemberRights
    }
  },
  methods: {
    validate ():void {
      this.$refs.form.validate()
    },
    async createGroup (e:Event):Promise<void> {
      this.validate()
      e.preventDefault()
      if (this.valid) {
        this.$store.commit('toggleLoader')
        await (new GroupService()).createGroup({ name: this.name, type: this.groupType, can_creat_club_events: this.canCreatClubEvents })
        await (new ClubService()).Load()
        this.$store.commit('toggleLoader')
      }
    }
  },
  created ():void {
    if (Authorization.HasCurrentClubMemberRight(ClubMemberRights.CLUB_TEAM_MANAGER)) {
      this.groupTypes.push({ text: this.$t('group.types.team'), value: GroupTypes.Team })
    }
    if (Authorization.HasCurrentClubMemberRight(ClubMemberRights.CLUB_COMMISSION_MANAGER)) {
      this.groupTypes.push({ text: this.$t('group.types.commission'), value: GroupTypes.Commission })
    }
  }
}
