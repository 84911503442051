
import EventCard from '@/components/Event/EventDialog/EventCard.vue'
import ClubMemberService from '@/services/Enitities/ClubMemberService'
export default {
  name: 'MemberInvites',
  components: { EventCard },
  props: {
    clubMember: {
      required: true
    },
    count: {
      required: true
    }
  },
  data ():unknown {
    return {
      dialog: false,
      loading: false,
      events: []
    }
  },
  methods: {
    async loadEvents ():Promise<void> {
      this.loading = true
      this.events = await (new ClubMemberService().getMemberInvites(this.clubMember.id))
      this.loading = false
    }
  }

}
