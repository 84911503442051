import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import Application from '@/services/App/Application'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: Application.IsDarkMode(),
    themes: {
      light: {
        primary: '#A10093',
        secondary: '#E9710B',
        accent: '#8c9eff',
        error: '#b71c1c'
      }
    }
  }
})
