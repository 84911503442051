<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:serif="http://www.serif.com/" width="100%" height="100%" viewBox="0 0 936 807" version="1.1" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g transform="matrix(1,0,0,1,-532.173,-2846.94)">
        <g transform="matrix(1,0,0,1,0,2250)">
            <g id="Layer-1" serif:id="Layer 1">
                <g transform="matrix(-0.422616,2785.8,2785.8,0.422616,819.728,620.774)">
                    <path d="M0.281,-0.103L0.281,0.024L0.255,0.032C0.248,0.026 0.24,0.022 0.232,0.019L0.133,-0.022L0.016,0.073C0.006,0.081 -0.003,0.092 -0.009,0.103L-0.009,-0.023L0.281,-0.103Z" style="fill:url(#_Linear1);fill-rule:nonzero;"/>
                </g>
              <g transform="matrix(-0.422616,2785.8,2785.8,0.422616,1130.31,620.821)">
                    <path d="M0.023,-0.03L0.135,-0.121L0.228,-0.083C0.26,-0.07 0.281,-0.039 0.281,-0.005L0.281,0.064L0.135,0.005L-0.009,0.121L-0.009,0.035C-0.009,0.01 0.003,-0.014 0.023,-0.03Z" style="fill:url(#_Linear2);fill-rule:nonzero;"/>
                </g>
            </g>
        </g>
    </g>
    <defs>
        <linearGradient id="_Linear1" x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(1,2.71051e-20,2.71051e-20,-1,0,-5.18623e-07)"><stop offset="0" style="stop-color:rgb(255,0,148);stop-opacity:1"/><stop offset="1" style="stop-color:rgb(0,36,156);stop-opacity:1"/></linearGradient>
      <linearGradient id="_Linear2" x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(1,2.71051e-20,2.71051e-20,-1,0,-1.27703e-07)"><stop offset="0" style="stop-color:rgb(255,0,148);stop-opacity:1"/><stop offset="1" style="stop-color:rgb(0,36,156);stop-opacity:1"/></linearGradient>
    </defs>
</svg>
</template>

<script>
export default {
  name: 'KeevaLogo'
}
</script>

<style scoped>

</style>
