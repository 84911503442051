
import Dashboard from '@/components/Main/Dashboard.vue'
import { ClubMemberRights, GroupMemberRights, EventInviteStatusTypes, EventTypes } from '@/models/Enum'
import Authorization from '@/services/App/Authorization'
import GroupEventService from '@/services/Enitities/GroupEventService'
import Attendance from '@/components/Event/EventDialog/Attendance.vue'
import EventDetails from '@/components/Event/EventDialog/EventDetails.vue'
import EventEdit from '@/components/Event/EventDialog/EventEdit.vue'
import EventJobCreateDialog from '@/components/Event/EventJobs/EventJobCreateDialog.vue'
import EventDelete from '@/components/Event/EventDialog/EventDelete.vue'
import EventJobsItem from '@/components/Event/EventJobs/EventJobsItem.vue'
import EventOpenInvite from '@/components/Event/EventDialog/EventOpenInvite.vue'
import { GroupEvent } from '@/models/GroupEvent'
import ClubLogo from '@/components/Club/ClubLogo.vue'
export default {
  name: 'GroupEvent',
  components: { ClubLogo, EventOpenInvite, EventJobsItem, EventDelete, EventJobCreateDialog, EventEdit, EventDetails, Attendance, Dashboard },
  data ():unknown {
    return {
      loading: true,
      ClubMemberRights,
      GroupMemberRights,
      Authorization,
      groupEvent: {},
      tab: null,
      EventInviteStatusTypes,
      inThePast: false,
      EventTypes,
      jobs: []
    }
  },
  watch: {
    async $route ():Promise<void> {
      this.loading = true
      this.groupEvent = await (new GroupEventService()).GetEvent(this.$route.params.id)
      this.inThePast = (new Date(this.groupEvent.start)) < (new Date())
      this.jobs = this.groupEvent?.group_event_jobs?.sort((a, b) => (a.title > b.title) ? 1 : -1)
      this.loading = false
    }
  },
  async created ():Promise<void> {
    this.loading = true
    this.groupEvent = await (new GroupEventService()).GetEvent(this.$route.params.id)
    this.inThePast = (new Date(this.groupEvent.start)) < (new Date())
    this.jobs = this.groupEvent?.group_event_jobs?.sort((a, b) => (a.title > b.title) ? 1 : -1)
    this.loading = false
  },
  methods: {
    getColor (groupEvent:GroupEvent):string {
      return GroupEventService.getColor(groupEvent)
    },
    canShowTasks ():boolean {
      if (Authorization.HasCurrentGroupMemberRight(GroupMemberRights.GROUP_EVENT_JOBS, this.groupEvent.group)) {
        return true
      }

      if (this.groupEvent.group_event_jobs && Authorization.HasCurrentGroupMemberRight(GroupMemberRights.GROUP_MEMBER, this.groupEvent.group)) {
        return this.groupEvent.group_event_jobs.length > 0
      }

      return false
    }
  }
}
