
import Dashboard from '@/components/Main/Dashboard.vue'
import ProfileCard from '@/components/User/ProfileCard.vue'
import ClubMemberRegistration from '@/components/User/ClubMemberRegistration.vue'
import Authentication from '@/services/App/Authentication'
import ParentSwitch from '@/components/Parent/ParentSwitch.vue'
import ProfileSettings from '@/components/User/ProfileSettings.vue'
import MemberiCal from '@/components/Member/MemberiCal.vue'
import { LocalStorage, StorageNames } from '@/services/App/LocalStorage'
import ClubMemberRegistry from '@/components/Security/Registration/ClubMemberRegistry.vue'
import MainActionCard from '@/components/Main/MainActionCard.vue'

export default {
  name: 'Profile',
  components: { MainActionCard, ClubMemberRegistry, MemberiCal, ProfileSettings, ParentSwitch, ClubMemberRegistration, ProfileCard, Dashboard },
  data ():unknown {
    return {
      user: Authentication.getUser(),
      members: LocalStorage.get(StorageNames.Members),
      clubMemberRegiDialog: false
    }
  }
}
