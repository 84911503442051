import VueRouter from 'vue-router'
import Authentication from '@/services/App/Authentication'
import { ClubMemberRights } from '@/models/Enum'
import Authorization from '@/services/App/Authorization'
import { RouteConfig } from 'vue-router/types/router'
import AppRoutes from '@/router/App'
import DashboardRoutes from '@/router/dashboard'
import IntegrationRoutes from '@/router/intergration'

const routes: RouteConfig[] = []

const router = new VueRouter({
  routes: routes
    .concat(AppRoutes)
    .concat(IntegrationRoutes)
    .concat(DashboardRoutes)
})

router.beforeEach(async (to, from, next) => {
  if (!await Authentication.IsAuthenticated()) {
    if (to.path.startsWith('/dashboard')) {
      next({ name: 'login' })
    }
  }
  // Validate if route has right
  if (to.meta) {
    if (to.meta.clubRight) {
      if (!Authorization.HasCurrentClubMemberRight(to.meta.clubRight as ClubMemberRights)) {
        next({ name: 'Dashboard' })
      }
    }
  }
  next()
})

export default router
