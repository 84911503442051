
import GroupService from '@/services/Enitities/GroupService'
import Dashboard from '@/components/Main/Dashboard.vue'
import { GroupTypes, ClubMemberRights } from '@/models/Enum'
import CreateNewGroupDialog from '@/components/Club/Group/CreateNewGroupDialog.vue'
import Authorization from '@/services/App/Authorization'
import ClubLogo from '@/components/Club/ClubLogo.vue'
import { Group } from '@/models/Group'
import Application from '@/services/App/Application'
import ClubService from '@/services/Enitities/ClubService'

export default {
  name: 'Commissions',
  components: { ClubLogo, CreateNewGroupDialog, Dashboard },
  data ():unknown {
    return {
      groups: [],
      club: ClubService.get(),
      Application,
      Authorization,
      ClubMemberRights,
      GroupTypes
    }
  },
  async created ():Promise<void> {
    const groups = await (new GroupService()).getAll() as Group[]
    this.groups = groups.sort((a, b) => (a.name > b.name) ? 1 : -1).filter(g => !g.deleted_at)
  }
}
