
import Authorization from '@/services/App/Authorization'
import { EventInviteStatusTypes, GroupMemberRights, GroupMemberTypes } from '@/models/Enum'
import GroupEventInviteService from '@/services/Enitities/GroupEventInviteService'
import SearchClubMember from '@/components/Member/SearchClubMember.vue'
import AttendanceMemberTypes from '@/components/Event/EventDialog/AttendanceMemberTypes.vue'

export default {
  name: 'Attendance',
  components: { AttendanceMemberTypes, SearchClubMember },
  props: {
    groupEvent: {
      required: true
    }
  },
  data ():unknown {
    return {
      inThePast: (new Date(this.groupEvent.start)) < (new Date()),
      attendance: [],
      guestAttendance: [],
      GroupMembers: [],
      NotInvitedGroupMembers: [],
      selectedMember: null,
      GroupMemberTypes,
      EventInviteStatusTypes,
      Authorization,
      GroupMemberRights
    }
  },
  methods: {
    async loadAttendance ():Promise<void> {
      const GroupMembers = this.groupEvent.group.group_members
      const attendance = []
      const guestAttendance = []
      const invites = this.groupEvent.group_event_invites.sort((a, b) => (a.club_member.first_name > b.club_member.first_name) ? 1 : -1)
      invites.sort((a, b) => b.status - a.status).forEach(function (invite) {
        const tm = GroupMembers.find(tm => tm.club_member.id === invite.club_member.id)
        if (tm !== undefined) {
          invite.group_member = tm
          attendance.push(invite)
        } else {
          guestAttendance.push(invite)
        }
      })
      this.GroupMembers = GroupMembers
      this.attendance = attendance
      this.guestAttendance = guestAttendance
    },
    async addInvite (): Promise<void> {
      if (this.selectedMember) {
        this.$store.commit('toggleLoader')
        const response = await (new GroupEventInviteService()).addEvent(this.selectedMember, this.groupEvent)
        if (response) {
          this.guestAttendance.push(response)
          this.selectedMember = null
        }
        this.$store.commit('toggleLoader')
      }
    }
  },
  mounted ():void {
    this.loadAttendance()
  }
}
