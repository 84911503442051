
import GroupEventService from '@/services/Enitities/GroupEventService'
import moment from 'moment'
import GroupMemberService from '@/services/Enitities/GroupMemberService'
import { EventTypes, GroupMemberTypes, GroupTypes } from '@/models/Enum'
import SearchClubMember from '@/components/Member/SearchClubMember.vue'
import { GroupMember } from '@/models/GroupMember'
import ClubMemberService from '@/services/Enitities/ClubMemberService'
import Application from '@/services/App/Application'

export default {
  name: 'CreateGroupEvent',
  components: { SearchClubMember },
  props: {
    group: {
      required: true
    }
  },
  methods: {
    async getMembers ():Promise<void> {
      this.$store.commit('toggleLoader')
      const members = await (new GroupMemberService()).getMembers(this.group)
      const selectedMembers = {}
      members.forEach(function (GroupMember) {
        selectedMembers[GroupMember.club_member.id] = GroupMember.club_member
      })
      this.members.selected = selectedMembers
      this.$store.commit('toggleLoader')
    },
    validate ():void {
      this.$refs.form.validate()
    },
    removeSelectedMember (member:GroupMember):void {
      this.members.selected[member.id] = undefined
      delete this.members.selected[member.id]
    },
    addInvite ():void {
      if (this.addedMember) {
        this.members.selected[this.addedMember.id] = this.addedMember
        this.addedMember = null
      }
    },
    async createEvent (e:Event):Promise<void> {
      e.preventDefault()
      this.validate()
      if (this.valid) {
        this.$store.commit('toggleLoader')
        const response = await (new GroupEventService()).CreateEvent(this.group, {
          title: this.event.title,
          start: this.event.start,
          duration: this.event.duration,
          location: this.event.location,
          type: this.event.type,
          description: this.event.description,
          gathering_location: this.event.gatheringLocation,
          gathering_time: this.event.gatheringTime,
          repeat_amount: this.repeatAmount,
          member_ids_invite: Object.keys(this.members.selected),
          private: this.event.privateEvent,
          is_club_event: this.event.clubEvent,
          has_open_invitation: this.event.has_open_invitation
        })
        if (response) {
          this.dialog = false
          this.$store.commit('addMessage', {
            text: 'Event created'
          })
          this.event.title = ''
          this.event.description = ''
          this.event.duration = 0
          this.event.location = ''
          this.event.type = 0
          this.event.gatheringTime = ''
          this.event.repeatAmount = 0
          this.members.selected = {}
          this.event.privateEvent = false
          this.event.clubEvent = false
          this.event.has_open_invitation = false
        }
        this.$store.commit('toggleLoader')
      }
    },
    setRepeatAmount ():void {
      const start = moment(this.event.start)
      const until = moment(this.repeatUntil)
      this.repeatAmount = Math.floor(moment.duration(until.diff(moment(start))).asWeeks())
    }
  },
  data ():unknown {
    return {
      GroupMemberTypes,
      EventTypes,
      GroupTypes,
      valid: false,
      dialog: false,
      repeatAmount: 0,
      repeatUntil: '',
      inviteType: 'all',
      addedMember: null,
      user: ClubMemberService.getCurrentMember(),
      isDarkMode: Application.IsDarkMode(),
      members: {
        selected: {}
      },
      moment,
      event: {
        title: '',
        repeatAmountRules: [
          (v):boolean|string => v >= 0 || 'Amount needs to be a positive number'
        ],
        titleRules: [
          (v):boolean|string => !!v || this.$t('requiredField'),
          (v):boolean|string => v.length < 255 || 'Title can only be 255 characters'
        ],
        type: '1',
        typeRules: [
          (v):boolean|string => !!v || this.$t('requiredField')
        ],
        start: '',
        startRules: [
          (v):boolean|string => !!v || this.$t('requiredField')
        ],
        duration: '',
        durationRules: [
          (v):boolean|string => !!v || this.$t('requiredField'),
          (v):boolean|string => parseFloat(v) > 0 || 'Duration needs to be more then zero'
        ],
        location: '',
        locationRules: [
          (v):boolean|string => !!v || this.$t('requiredField'),
          (v):boolean|string => v.length < 255 || 'Title can only be 255 characters'
        ],
        description: '',
        descriptionRules: [
          (v):boolean|string => !!v || this.$t('requiredField'),
          (v):boolean|string => v.length < 255 || 'Description can only be 255 characters'
        ],
        gatheringTime: '',
        gatheringTimeRules: [
          (v):boolean|string => v < this.event.start.slice(11, 99) || 'Gathering time needs to before start time'
        ],
        gatheringLocation: '',
        gatheringLocationRules: [
          (v):boolean|string => (v.length < 255 && v.length >= 0) || 'Gathering location can only be 255 characters'
        ],
        clubEventRules: [
          ():boolean|string => !(this.event.clubEvent && this.event.privateEvent) || 'Event cant be a club event and a private event'
        ],
        privateEvent: this.group.type === GroupTypes.Commission,
        clubEvent: false,
        has_open_invitation: false
      }
    }
  }
}
