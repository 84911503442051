
import { WebPush } from '@/services/App/WebPush'
import { onTimeDialog, onTimeDialogs } from '@/services/App/onTimeDialog'

export default {
  name: 'WebPushCard',
  data ():unknown {
    return {
      WebPush,
      onTimeDialog,
      onTimeDialogs,
      showDialog: !onTimeDialogs.hasBeenShown(onTimeDialog.Notification)
    }
  },
  methods: {
    cancelNotifications ():void {
      onTimeDialogs.addSeenDialog(onTimeDialog.Notification)
      this.showDialog = false
    },
    acceptNotifications ():void {
      (new WebPush()).requestPermission()
      this.showDialog = false
    }
  }
}
