
import { EventInviteStatusTypes, GroupMemberRights } from '@/models/Enum'
import InviteSelection from '@/components/Event/EventInvite/InviteSelection.vue'
import MemberCard from '@/components/Member/MemberCard.vue'
import Authorization from '@/services/App/Authorization'
import { GroupEventInvite } from '@/models/GroupEventInvite'
import GroupEventInviteService from '@/services/Enitities/GroupEventInviteService'

export default {
  name: 'AttendanceMemberTypes',
  components: { MemberCard, InviteSelection },
  data ():unknown {
    return {
      inThePast: (new Date(this.groupEvent.start)) < (new Date()),
      EventInviteStatusTypes,
      Authorization,
      GroupMemberRights
    }
  },
  props: {
    invites: {
      required: true
    },
    name: {
      required: true
    },
    group: {
      required: true
    },
    disabled: {
      default: false,
      required: true
    },
    groupEvent: {
      required: true
    }
  },
  methods: {
    async removeInvite (invite: GroupEventInvite):Promise<void> {
      this.$store.commit('toggleLoader')
      const response = await (new GroupEventInviteService()).deleteInvitation(invite)
      if (response.ok) {
        this.invites = this.invites.filter(i => i.id !== invite.id)
      }
      this.$store.commit('toggleLoader')
    }
  }
}
