
import EventCard from '@/components/Event/EventDialog/EventCard.vue'
import MainActionCard from '@/components/Main/MainActionCard.vue'

export default {
  name: 'ClubEventsListItem',
  components: { MainActionCard, EventCard },
  props: {
    clubEvents: {
      type: Array
    }
  },
  data ():unknown {
    return {
      dialog: false
    }
  }
}
