
import moment from 'moment'
import ClubService from '@/services/Enitities/ClubService'
import UserService from '@/services/Enitities/UserService'

export default {
  name: 'UserRegistrationDialog',
  methods: {
    async createUser ():Promise<void> {
      this.emailError = false
      this.loading = true
      const response = await UserService.registerUser(this.member)
      const data = await response.json()
      if (response.ok) {
        this.stepper = 4
        this.error = null
      } else {
        this.error = data.message
        this.emailError = true
      }
      this.loading = false
    }
  },
  data ():unknown {
    return {
      moment,
      club: ClubService.get(),
      stepper: 1,
      dialog: false,
      emailError: false,
      error: '',
      loading: false,
      member: {
        firstname: '',
        lastname: '',
        email: '',
        birthdate: '',
        password_validate: '',
        password: ''
      },
      validUserForm: false,
      validUserPasswordForm: false,
      validChildrenForm: false,
      validConsentForm: false,
      rules: {
        firstname: [
          (v):boolean|string => !!v || this.$t('requiredField')
        ],
        lastname: [
          (v):boolean|string => !!v || this.$t('requiredField')
        ],
        email: [
          (v):boolean|string => !!v || this.$t('requiredField'),
          (v):boolean|string => /.+@.+\..+/.test(v) || this.$t('emailValid')
        ],
        childEmail: [
          (v):boolean|string => (/.+@.+\..+/.test(v) || v.length === 0) || v.length === 0 || this.$t('emailValid'),
          (v):boolean|string => (v !== this.member.email || v.length === 0) || "You can't use the same email."
        ],
        birthdate: [
          (v):boolean|string => !!v || this.$t('requiredField'),
          (v):boolean|string => moment().diff(v, 'years', true) > 13 || 'You need to be at least 13 years old to create your own account'
        ],
        birthdateAllAges: [
          (v):boolean|string => !!v || this.$t('requiredField')
        ],
        userPassword: [
          (v):boolean|string => !!v || this.$t('requiredField'),
          (v):boolean|string => v.length >= 8 || this.$t('passwordLength')
        ],
        childPassword: [
          (v):boolean|string => (v.length >= 8 || v.length === 0) || this.$t('passwordLength')
        ],
        consent: [
          (v):boolean|string => !!v || this.$t('requiredField')
        ]
      }
    }
  }
}
