import { Api } from '@/services/API/Api'
import { LocalStorage, StorageNames } from '@/services/App/LocalStorage'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Cookies from 'js-cookie'
import { Store } from 'vuex'
import NotificationService from '@/services/Enitities/NotificationService'
import Application from '@/services/App/Application'
import { User } from '@/models/User'

export class Authentication {
  api:Api
  constructor () {
    this.api = new Api()
  }

  public async loginUser (login = {}): Promise<boolean> {
    const response = await this.api.request('authentication/login', 'POST', login)
    if (response.ok) {
      const resultObj = await response.json()
      Application.setLocalData(resultObj)
      return true
    }
    return false
  }

  public static async IsAuthenticated (): Promise<boolean> {
    // When cookie is set user is authenticated
    return await Cookies.get('Device_AUTH') !== undefined && Cookies.get('Device_AUTH') !== ''
  }

  public static getUser () : User {
    const user = LocalStorage.get(StorageNames.User)
    return user as User
  }

  public static setUser (member:User):void {
    LocalStorage.set(StorageNames.User, member)
  }

  public async CheckAuthentication (store?:Store<any>): Promise<boolean> {
    const result = await this.api.request('authentication/user')
    if (result.ok) {
      const resultObj = await result.json()
      Application.setLocalData(resultObj)
      if (resultObj.notifications && store) {
        store.commit('setNotifications', NotificationService.getAllLocalNotifications())
      }
      return true
    }
    return false
  }
}

export default Authentication
