
import InviteSelection from '@/components/Event/EventInvite/InviteSelection.vue'
import moment from 'moment'
import { EventTypes, ClubMemberRights } from '@/models/Enum'
import OpenInviteSelection from '@/components/Event/EventInvite/OpenInviteSelection.vue'
import GroupEventService from '@/services/Enitities/GroupEventService'
import Authorization from '@/services/App/Authorization'

export default {
  name: 'EventCard',
  components: { OpenInviteSelection, InviteSelection },
  props: {
    groupEvent: {
      required: true
    },
    slim: {
      default: false
    },
    group: {
      required: false
    }
  },
  data ():unknown {
    return {
      Authorization,
      start: moment(this.groupEvent.start),
      end: moment(this.groupEvent.end),
      gatheringTime: moment(this.groupEvent.gathering_time),
      inThePast: (new Date(this.groupEvent.start)) < (new Date()),
      isUpcoming: Math.ceil(Math.abs((new Date(this.groupEvent.start)).getTime() - (new Date()).getTime()) / (60 * 60 * 24)) <= 1 || this.groupEvent.type === EventTypes.Game,
      EventTypes,
      GroupEventService,
      ClubMemberRights,
      openInviteLoading: false
    }
  },
  methods: {
    goToEvent ():void {
      this.$router.push({ name: 'GroupEvent', params: { id: this.groupEvent.id } })
    }
  }
}
