import { LocaleMessageObject } from 'vue-i18n'

export default {
  save: 'Opslaan',
  cancel: 'Annuleren',
  edit: 'Bewerken',
  delete: 'Verwijderen',
  create: 'Aanmaken',
  add: 'Toevoegen',
  search: 'Zoeken',
  ok: 'Oke',
  done: 'Klaar',
  enable: 'Zet aan',
  understand: 'Begrepen',
  requiredField: 'Dit is verplicht',
  nothingFound: 'Niks gevonden',
  passwordLength: 'Het wachtwoord moet minimaal 8 karkaters lang zijn',
  emailValid: 'Emailadres moet correct zijn',
  actionNotUndo: 'Deze actie kan niet ongedaan worden gemaakt',
  title: 'Titel',
  description: 'Omschrijving',
  event: {
    more: 'Meer evenementen',
    create: 'Nieuw evenement aanmaken',
    event: 'Evenement',
    events: 'Evenementen',
    attendance: 'Aanwezigheid',
    yourEvents: 'Jouw aankomende evenementen',
    notFound: 'Geen evenementen gevonden',
    title: 'Titel',
    description: 'Omschrijving',
    location: 'Locatie',
    duration: 'Duratie',
    durationFormat: 'In uren',
    startTime: 'Start tijd',
    upcoming: 'Aanstaande',
    past: 'Geweest',
    options: {
      gathering: {
        name: 'Verzamel opties'
      },
      advanced: {
        name: 'Geavanceerde opties',
        privateEvent: 'Alleen voor uitgenodigde leden',
        clubEvent: 'Club evenement',
        privateEventDescription: 'Evenement alleen zichtbaar voor uitgenodigde leden',
        clubEventDescription: 'Evenement wordt weergegeven aan alle club leden, Met open uitnodigingen kan aanwezigheid op gegeven worden.'
      },
      invites: {
        name: 'Uitnodiging opties',
        open: 'Open aanmelding, alle Club leden'
      },
      cancel: {
        name: 'Annuleer evenement',
        description: 'Annuleer dit evenement'
      },
      delete: {
        name: 'Evenement verwijderen',
        description: 'Verwijder dit evenement'
      },
      repeat: {
        name: 'Herhaal evenement',
        until: 'Herhaal evenement tot en met',
        amount: 'Aantal herhaal evenementen'
      }
    },
    type:
      {
        name: 'Soort',
        game: 'Wedstrijd',
        practice: 'Training',
        other: 'Anders',
        clubEvent: 'Club evenement',
        canceled: 'Geannuleerd'
      },
    gathering: {
      time: 'Verzamel tijd',
      location: 'Verzamel locatie'
    },
    invite: {
      name: 'Uitnodiging',
      decline: 'Afmelden',
      reason: 'Reden',
      areYouComing: 'Kom jij ook?'
    }
  },
  groupMembers: {
    member: 'Lid',
    members: 'Leden',
    type: 'Rol lid',
    types: {
      coaches: 'Coaches',
      chairmen: 'Voorzitter',
      member: 'Lid',
      coach: 'Coach',
      staff: 'Staff',
      player: 'Speler',
      players: 'Spelers'
    },
    rights: {
      GROUP_EVENTS: 'Groep evenementen',
      GROUP_EVENT_JOBS: 'Beheer van evenement taken'
    }
  },
  task: {
    edit: 'Bewerken taak',
    title: 'Titel',
    description: 'Omschrijving',
    yourTasks: 'Jouw taken',
    task: 'Taak',
    tasks: 'Taken',
    open: 'Open taken',
    takeTask: 'Taak oppakken',
    notFound: 'Geen taken gevonden',
    new: 'Nieuwe taak',
    reminderEvent: 'Herinnering, Lid heeft taken dit evenement.'
  },
  security: {
    login: {
      email: 'E-mailadres',
      password: 'Wachtwoord',
      login: 'Inloggen',
      loginError: 'Inloggen mislukt, Email en wachtwoord combinatie niet gevonden'
    },
    forgotPassword: {
      name: 'Wachtwoord vergeten'
    },
    resetPassword: {
      title: 'Verander jouw wachtwoord',
      requestReset: 'Nieuwe wachtwoord aanvragen'
    },
    registrationValidation: {
      title: 'Aangemeld als gast',
      description: 'Jij bent aangemeld als gast bij',
      next: 'De beheerder van de club is ingelight, Deze persoon zal jouw toevoegen aan de betsaande teams.'
    },
    registration: {
      ClubMember: 'Registreer als club lid',
      doYouHaveAnAccount: 'Heb je nog geen account?',
      stayUpToDate: 'Blijf op de hoogte van jouw club',
      CreateAnAccount: 'Maak een account aan',
      CreateAccount: 'Registreer',
      Type: 'Account typen',
      YourDetails: 'Jouw details',
      AddAnChild: 'Voeg kind toe',
      RemoveAnChild: 'Verwijder kind',
      Children: 'Kinderen',
      ConnectedMembers: 'Verbonden club leden',
      Child: 'Kind',
      ChildAccount: 'Heb jij kinderen die ook lid zijn van de club?',
      ChildAccountDetail: 'Voeg ze hier toe, jij heb inzagen en controle',
      ChildCreateAccount: 'Maak een account voor je kind. Dit is optioneel',
      ChildAdd: 'Voeg jouw kind toe aan Keeva.',
      ChildInformation: 'Informatie over jouw kind',
      SomeInformation: 'Wat informatie over jouw',
      EmailValidation: 'Email valideren',
      EmailValidationDetails: 'Een email is naar je verzonden, Volg de link in de email om je account te activeren.',
      done: 'Je aanmelding is compleet',
      TermsOfService: 'Algemene voorwaarden',
      TermsOfServiceAgreement: 'Ik acepteer de algemen voorwaarden van ',
      PrivacyPolicy: 'Privacybeleid ',
      PrivacyPolicyAgreement: 'Ik acepteer het Privacybeleid van ',
      YourTeams: 'Wat is jouw team?',
      Types: {
        Title: 'Wie ben jij?',
        ClubMember: 'Ik ben een club lid',
        Parent: 'Ik ben een ouder, Mijn kind is een club lid',
        Guest: 'Ik ben een gast'
      }
    }
  },
  clubMember: {
    name: 'Club leden',
    contactInformation: 'Gegevens',
    firstname: 'Voornaam',
    lastname: 'Achternaam',
    email: 'Email',
    password: 'Wachtwoord',
    passwordValidation: 'Wachtwoord herhalen',
    birthdate: 'Gebroortedag',
    mobileNumber: 'Telefoon nummer',
    years: 'Jaar',
    profile: {
      name: 'Persoonlijke informatie',
      logout: 'Uitloggen',
      settings: {
        name: 'Instellingen',
        user: {
          name: 'Gebruiker',
          language: 'Taal'
        },
        device: {
          name: 'Apperaat',
          theme: 'Thema',
          notification: 'Notificatie',
          notificationDescription: 'Zet notificaties aan om op de hoogte te blijven van jouw activiteiten.'
        }
      },
      calendarSync: {
        title: 'Synconiseer jouw evenementen',
        description: 'Jouw persoonlijke evenmenten in Keeva, koppel ze nu aan jouw persoonlijke agenda',
        copy: 'Kopiëren'
      }
    },
    connectMembers: {
      name: 'Ouders',
      types: {
        type: 'Soort',
        child: 'Kind',
        parent: 'Ouder',
        typeDescription: 'Welke soort zal de gekoppelde lid zijn?'
      },
      AddNewConnection: 'Ouder toevoegen',
      ExistingConnection: 'Bestaande ouders',
      NoExistingConnection: 'Geen ouders gevonden'
    },
    guest: {
      name: 'Club gasten',
      move: 'Gast naar lid'
    },
    rights: {
      name: 'Club rechten',
      CLUB_MEMBER_MANAGER: 'Club leden beheren',
      CLUB_GROUP_MANAGER: 'Groepen beheren',
      CLUB_TEAM_MANAGER: 'Teams beheren',
      CLUB_COMMISSION_MANAGER: 'Commissies beheren',
      CLUB_MANAGER: 'Hoofd admin'
    }
  },
  group: {
    group: 'Groep',
    groups: 'Groepen',
    availableGroups: 'Beschikbare groepen',
    memberOfGroups: 'Lid van groepen',
    name: 'Naam',
    type: 'Soort',
    typeHelp: 'Een team heeft trainingen en wedstrijden, een commissie heeft alleen overige evenementen ',
    clubEvents: 'Groep kan Club evenementen maken.',
    clubEventsHelp: 'Club evenemeneten worden gepresenteerd aan alle club leden, en wordt gevraagt voor hun deelname',
    new: 'Nieuwe groep aanmaken',
    types: {
      teams: 'Teams',
      team: 'Team',
      commission: 'Commissie',
      commissions: 'Commissies'
    }
  },
  serviceWorker: {
    update: {
      name: 'Nieuwe versie Keeva',
      description: 'Er is een nieuwe verie beschikbaar, update Keeva om de laatste versie te gebruiken.',
      update: 'Update'
    },
    install: {
      name: 'Keeva installeren',
      description: 'Installeer Keeva als een app op de telefoon voor makkelijker gebruik.',
      descriptioniOs: 'Installeer Keeva als een app op de telefoon voor makkelijker gebruik. Volg deze stappen',
      addToHomeScreen: 'Zet op beginscherm',
      install: 'Installeer Keeva'
    }
  },
  admin: {
    settings: 'Club instellingen',
    panel: 'Administrator panel'
  },
  navigation: {
    myGroups: 'Mijn groepen',
    club: 'Club',
    dashboard: 'Dashboard'
  },
  calender: {
    clubCalendar: 'Club agenda',
    clubCalenderDescription: 'Alle evenementen van alle teams en comissies van  '
  },
  transparency: 'Zichtbaarheid',
  news: {
    name: 'Nieuws',
    transparency: {
      0: 'Voor groeps leden',
      1: 'Voor club leden',
      2: '',
      3: 'Publiekelijk'
    }
  }
} as LocaleMessageObject
