import { Api } from '@/services/API/Api'
import { Group } from '@/models/Group'
import { GroupEvent } from '@/models/GroupEvent'
import { GroupEventInvite } from '@/models/GroupEventInvite'
import { EventTypes } from '@/models/Enum'

class GroupEventService {
  private api: Api

  constructor () {
    this.api = new Api()
  }

  static getColor (groupEvent:GroupEvent):string {
    switch (groupEvent.type) {
      default:
      case EventTypes.Game:
        return 'secondary'
      case EventTypes.Practice:
        return 'primary'
      case EventTypes.Other:
        return ''
    }
  }

  public async CreateEvent (team: Group, details = {}): Promise<GroupEvent | undefined> {
    const response = await this.api.request('group/' + team.id + '/event/create', 'POST', details)
    if (response.ok) {
      return await response.json() as GroupEvent
    }
  }

  public async UpdateEvent (teamEvent:GroupEvent): Promise<GroupEvent | undefined> {
    const response = await this.api.request('event/' + teamEvent.id, 'POST', teamEvent)
    if (response.ok) {
      return await response.json() as GroupEvent
    }
  }

  public async DeleteEvent (teamEvent:GroupEvent): Promise<Response> {
    return await this.api.request('event/' + teamEvent.id, 'DELETE')
  }

  public async CancelEvent (teamEvent:GroupEvent, cancelReason:string):Promise<GroupEvent|undefined> {
    const response = await this.api.request('event/' + teamEvent.id + '/cancel', 'POST', {
      cancel_reason: cancelReason
    })
    if (response.ok) {
      return await response.json() as GroupEvent
    }
  }

  public async GetEvents (team:Group):Promise<GroupEvent[] | undefined> {
    const response = await this.api.request('group/' + team.id + '/event/upcoming')
    if (response.ok) {
      return await response.json()
    }
  }

  public async GetEvent (eventId:string):Promise<GroupEvent | undefined> {
    const response = await this.api.request('event/' + eventId)
    if (response.ok) {
      return await response.json() as GroupEvent
    }
  }

  public async GetAttendance (event:GroupEvent):Promise<GroupEventInvite[]|undefined> {
    const response = await this.api.request('event/' + event.id + '/attendance')
    if (response.ok) {
      return await response.json()
    }
  }

  public async getEvents (team:Group, limit = 25, offset = 0, order = 'ASC', invited = false) :Promise<{events:GroupEvent[], amount:number}> {
    const request = await this.api.request('group/' + team.id + '/event', 'GET', {
      limit: limit,
      offset: offset,
      order: order,
      invited: invited
    })
    return await request.json()
  }
}

export default GroupEventService
