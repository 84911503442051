
import ClubMemberService from '@/services/Enitities/ClubMemberService'
import { ClubMemberRights } from '@/models/Enum'
import Dashboard from '@/components/Main/Dashboard.vue'
import MemberDetails from '@/components/Member/Sections/MemberDetails.vue'
import MemberParents from '@/components/Member/Sections/MemberParents.vue'
import Authorization from '@/services/App/Authorization'
import MemberRights from '@/components/Member/Sections/MemberRights.vue'
import MemberGuest from '@/components/Member/Sections/MemberGuest.vue'
import MemberGroups from '@/components/Member/Sections/MemberGroups.vue'
import MemberUser from '@/components/Member/Sections/MemberUser.vue'
import MemberRemove from '@/components/Member/Sections/MemberRemove.vue'
import ClubLogo from '@/components/Club/ClubLogo.vue'
import MemberAttendance from '@/components/Member/Sections/MemberAttendance.vue'
import MemberInvites from '@/components/Member/Sections/MemberInvites.vue'
import MemberTasks from '@/components/Member/Sections/MemberTasks.vue'

export default {
  name: 'Member',
  components: {
    MemberTasks,
    MemberInvites,
    MemberAttendance,
    ClubLogo,
    MemberRemove,
    MemberUser,
    MemberGroups,
    MemberGuest,
    MemberRights,
    MemberParents,
    MemberDetails,
    Dashboard
  },
  data ():unknown {
    return {
      tab: null,
      loadingMember: false,
      clubMember: {},
      isAClubMember: false,
      attendancePercentage: 0,
      analytics: {},
      Authorization,
      ClubMemberRights
    }
  },
  watch: {
    $route ():void {
      this.loadMember(this.$route.params.id)
    },
    clubMember: {
      handler ():void {
        this.isAClubMember = this.clubMember.rights.includes(ClubMemberRights.CLUB_MEMBER)
      },
      deep: true
    }
  },
  created ():void {
    this.loadMember(this.$route.params.id)
  },
  methods: {
    async loadMember (id:string):Promise<void> {
      if (!this.loadingMember) {
        this.$store.commit('toggleLoader')
        this.loadingMember = true
        const response = await (new ClubMemberService()).getMember(id)
        if (response) {
          this.clubMember = response.member
          this.analytics = response.analytics
          this.isAClubMember = this.clubMember.rights.includes(ClubMemberRights.CLUB_MEMBER)
        }
        this.loadingMember = false
        this.$store.commit('toggleLoader')
      }
    }
  }
}
