import { Api } from '@/services/API/Api'
import { LocalStorage, StorageNames } from '@/services/App/LocalStorage'
import { ClubMember } from '@/models/ClubMember'
import { ClubMemberRelation } from '@/models/ClubMemberRelation'
import { User } from '@/models/User'

export class ClubMemberService {
  api:Api
  constructor () {
    this.api = new Api()
  }

  public static getCurrentMember (): ClubMember|null {
    const member = LocalStorage.get(StorageNames.SelectedMember)
    if (member !== null) {
      return member as ClubMember
    }
    return null
  }

  public static setCurrentMember (selectedMember: ClubMember|null):void {
    LocalStorage.set(StorageNames.SelectedMember, selectedMember)
  }

  public async getMember (id:string): Promise<
    {member:ClubMember, analytics: {attendancePercentage:number, graph: {[week:number]: unknown}, upcomingInvites: number, upcomingTasks: number}} | false> {
    const response = await this.api.request('clubMember/' + id)
    if (response.ok) {
      return await response.json() as {member:ClubMember, analytics: {attendancePercentage:number, graph: {[week:number]: unknown}, upcomingInvites: number, upcomingTasks: number}}
    }
    return false
  }

  public async getMemberTasks (id:string): Promise<boolean|unknown> {
    const response = await this.api.request('clubMember/' + id + '/tasks')
    if (response.ok) {
      return await response.json()
    }
    return false
  }

  public async getMemberInvites (id:string): Promise<boolean|unknown> {
    const response = await this.api.request('clubMember/' + id + '/invites')
    if (response.ok) {
      return await response.json()
    }
    return false
  }

  public async updateMember (id:string, member:ClubMember): Promise<ClubMember | false> {
    const response = await this.api.request('clubMember/' + id, 'POST', member)
    if (response.ok) {
      return await response.json() as ClubMember
    }
    return false
  }

  public async makeMember (member:ClubMember): Promise<ClubMember | false> {
    const response = await this.api.request('clubMember/' + member.id + '/makeMember', 'POST')
    if (response.ok) {
      return await response.json() as ClubMember
    }
    return false
  }

  public async removeMember (member:ClubMember): Promise<boolean> {
    const response = await this.api.request('clubMember/' + member.id + '/removeMember', 'POST')
    return response.ok
  }

  public async searchMember (search:string, offset = 0, limit = 12, membersOnly = true, guestOnly = false):Promise<{amount:string, result:ClubMember[]} | undefined> {
    const result = await this.api.request('clubMember/search', 'GET', {
      search: search,
      limit: limit,
      offset: offset,
      membersOnly: membersOnly,
      guestOnly: guestOnly
    })
    if (result.ok) {
      return await result.json()
    }
  }

  public async removeConnectedMember (clubMember:ClubMember, relation:string):Promise<ClubMemberRelation[]|undefined> {
    const result = await this.api.request('clubMember/' + clubMember.id + '/connectedMembers/remove', 'POST', {
      relationId: relation
    })
    if (result.ok) {
      return await result.json()
    }
  }

  public async addConnectedMember (clubMember:ClubMember, addedMember:User):Promise<ClubMemberRelation[]|undefined> {
    const result = await this.api.request('clubMember/' + clubMember.id + '/connectedMembers/add', 'POST', {
      connectedMemberId: addedMember.id
    })
    if (result.ok) {
      return await result.json()
    }
  }

  public async createClubMember (firstname: string, lastname:string, birthdate:string):Promise<Response> {
    return await this.api.request('clubMember/create', 'POST', {
      firstname: firstname,
      lastname: lastname,
      birthdate: birthdate
    })
  }

  public async duplicateMembers ():Promise<Response> {
    return await this.api.request('clubMember/duplicates')
  }

  public async getCount ():Promise<Response> {
    return await this.api.request('clubMember/count')
  }
}

export default ClubMemberService
