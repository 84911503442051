

import KeevaLogo from '@/components/Main/KeevaLogo.vue'
export default {
  name: 'MemberUser',
  components: { KeevaLogo },
  props: {
    user: {
      required: true
    }
  },
  data ():unknown {
    return {
      dialog: false
    }
  }

}
