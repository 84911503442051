import { LocalStorage, StorageNames } from '@/services/App/LocalStorage'
import { Club } from '@/models/Club'
import ClubService from '@/services/Enitities/ClubService'
import Authentication from '@/services/App/Authentication'
import moment from 'moment'
import { LocalSettings, LocalSettingNames } from '@/services/App/LocalSettings'
import { Store } from 'vuex'
import { ClubMember } from '@/models/ClubMember'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Cookies from 'js-cookie'

class Application {
  public static setPwaToClub (club:Club):void {
    const manifestElement = document.querySelector('link[rel=manifest]')
    const titleElement = document.querySelector('title')
    // eslint-disable-next-line no-unused-expressions
    document.querySelector('meta[name=theme-color]')?.setAttribute('content', club.background_color)
    // eslint-disable-next-line no-unused-expressions
    document.querySelector('link[rel=mask-icon]')?.setAttribute('color', club.theme_color)
    // eslint-disable-next-line no-unused-expressions
    document.querySelector('link[rel=icon][sizes="32x32"]')?.setAttribute('href', Application.getApiUrl() + '/resource/' + club.id + '/icon_x32.png')
    // eslint-disable-next-line no-unused-expressions
    document.querySelector('link[rel=icon][sizes="16x16"]')?.setAttribute('href', Application.getApiUrl() + '/resource/' + club.id + '/icon_x16.png')
    // eslint-disable-next-line no-unused-expressions
    document.querySelector('link[rel=apple-touch-icon]')?.setAttribute('href', Application.getApiUrl() + '/resource/' + club.id + '/icon_x152.png')
    // eslint-disable-next-line no-unused-expressions
    document.querySelector('meta[name=apple-mobile-web-app-title]')?.setAttribute('content', club.name)
    // eslint-disable-next-line no-unused-expressions
    document.querySelector('meta[name=msapplication-TileImage]')?.setAttribute('content', Application.getApiUrl() + '/resource/' + club.id + '/icon_x144.png')
    document.querySelectorAll('link[rel=apple-touch-startup-image]').forEach(function (element) {
      element.setAttribute('href', Application.getApiUrl() + '/resource/' + club.id + element.getAttribute('href').replace('/images/keeva/splash_screens', ''))
    })
    if (titleElement && manifestElement) {
      titleElement.innerHTML = club.name
      manifestElement.setAttribute('href', Application.getApiUrl() + '/resource/' + club.id + '/manifest.json')
      manifestElement.setAttribute('crossorigin', 'use-credentials')
    }
  }

  public static async reloadLocalData (store:Store<unknown>): Promise<void> {
    if (await Authentication.IsAuthenticated()) {
      // When data is older than 5 min get new data
      if (LocalStorage.getDate(StorageNames.User) < moment().subtract(1, 'minutes').toDate()) {
        await (new Authentication()).CheckAuthentication(store)
      }
    }

    // When data is older than 5 hours reload data
    if (LocalStorage.getDate(StorageNames.Club) < moment().subtract(5, 'hours').toDate()) {
      await (new ClubService()).Load()
    }
  }

  public static setLocalData (data:{user:unknown, selectedMember:unknown, members:[], notifications:[], auth?:unknown}): void {
    if (data.user) {
      LocalStorage.set(StorageNames.User, data.user)
    }
    if (data.selectedMember) {
      LocalStorage.set(StorageNames.SelectedMember, data.selectedMember as ClubMember)
    }
    if (data.notifications) {
      LocalStorage.set(StorageNames.Notifications, data.notifications)
    }
    if (data.auth) {
      Cookies.set('Device_AUTH', JSON.stringify(data.auth), { expires: 50 })
    }
    if (data.members) {
      LocalStorage.set(StorageNames.Members, data.members)
    }
  }

  public static IsDarkMode ():boolean {
    const setting = parseInt(<string>LocalSettings.get(LocalSettingNames.Theme))
    if (setting === 1) {
      return true
    }

    if (setting === 2) {
      return false
    }

    if (window.matchMedia) {
      return window.matchMedia('(prefers-color-scheme: dark)').matches
    }

    return false
  }

  public static getApiUrl ():string {
    if (process.env.NODE_ENV === 'development') {
      return 'http://localhost:5050'
    }
    const names = window.location.hostname.split('.')
    delete names[0]
    return 'https://api.keeva.info'
  }
}

export default Application
