
import Dashboard from '@/components/Main/Dashboard.vue'
import ClubService from '@/services/Enitities/ClubService'
import ClubLogo from '@/components/Club/ClubLogo.vue'
import ClubCalendar from '@/components/Club/ClubCalendar.vue'
export default {
  name: 'CalendarOverview',
  components: { ClubCalendar, ClubLogo, Dashboard },
  data ():unknown {
    return {
      club: ClubService.get()
    }
  }
}
