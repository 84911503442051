
import ClubMemberService from '@/services/Enitities/ClubMemberService'
import ClubLogo from '@/components/Club/ClubLogo.vue'
import CurrentGroupList from '@/components/Main/Dashboard/CurrentGroupList.vue'

export default {
  name: 'BottomNavigation',
  components: { CurrentGroupList, ClubLogo },
  data ():unknown {
    return {
      clubMember: {}
    }
  },
  created ():void {
    this.clubMember = ClubMemberService.getCurrentMember()
  }
}
