export enum StorageNames{
  User= 'User',
  Teams='Teams',
  Club = 'Club',
  SelectedMember = 'SelectedMember',
  LocalSettings = 'LocalSettings',
  OnTimeDialogs = 'OnTimeDialogs',
  Notifications = 'notifications',
  Members= 'clubMembers'
}

export class LocalStorage {
  public static set (item:StorageNames, value: unknown):void {
    localStorage.setItem(item, JSON.stringify({ data: value, date: Date.now() }))
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public static get (item:StorageNames):false|object {
    const result = localStorage.getItem(item)
    if (result) {
      const Object = JSON.parse(result)
      if (Object.data) {
        return Object.data
      }
    }
    return {}
  }

  public static getDate (item:StorageNames):Date|false {
    const result = localStorage.getItem(item)
    if (result) {
      const Object = JSON.parse(result)
      if (Object.data) {
        return new Date(Object.date)
      }
    }
    return false
  }

  public static delete (item:StorageNames):void {
    localStorage.removeItem(item)
  }
}

export default { LocalStorage, StorageNames }
