
import Vue from 'vue'
import ClubService from '@/services/Enitities/ClubService'
import Loader from '@/components/Main/Loader.vue'
import PwaPrompt from '@/components/Main/PwaPrompt.vue'
import Application from '@/services/App/Application'

export default Vue.extend({
  name: 'App',
  components: { Loader, PwaPrompt },
  data () {
    return {
      clubIsLoaded: false
    }
  },
  async created () {
    if (!('id' in ClubService.get())) {
      this.$store.commit('toggleLoader')
      await (new ClubService()).Load()
      this.$store.commit('toggleLoader')
    }
    this.clubIsLoaded = true
    const club = ClubService.get()
    this.$vuetify.theme.themes.light.primary = club.theme_color
    this.$vuetify.theme.themes.dark.primary = club.theme_color
    this.$vuetify.theme.themes.light.secondary = club.background_color
    this.$vuetify.theme.themes.dark.secondary = club.background_color
    Application.setPwaToClub(club)
  }
})
