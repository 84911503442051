
import Dashboard from '@/components/Main/Dashboard.vue'
import GroupService from '@/services/Enitities/GroupService'
import { ClubMemberRights, GroupMemberRights } from '@/models/Enum'
import Authorization from '@/services/App/Authorization'
import EditGroupDialog from '@/components/Group/Edit/GroupEditDialog.vue'
import ClubLogo from '@/components/Club/ClubLogo.vue'
import MainActionCard from '@/components/Main/MainActionCard.vue'
import Application from '@/services/App/Application'
import ClubService from '@/services/Enitities/ClubService'
import EventCard from '@/components/Event/EventDialog/EventCard.vue'

export default {
  name: 'Group',
  components: { EventCard, MainActionCard, ClubLogo, EditGroupDialog, Dashboard },
  data (): unknown {
    return {
      Application,
      ClubMemberRights,
      GroupMemberRights,
      Authorization,
      group: null,
      loading: true,
      count: {},
      events: [],
      tab: null,
      club: ClubService.get(),
      icalUrl: ''
    }
  },
  watch: {
    async $route ():Promise<void> {
      await this.load()
    }
  },
  async created ():Promise<void> {
    await this.load()
  },
  methods: {
    async load ():Promise<void> {
      this.$store.commit('toggleLoader')
      this.group = await (new GroupService()).get(this.$route.params.id)
      this.loading = true;
      (new GroupService()).getCount(this.$route.params.id).then((response) => {
        this.count = response
        this.loading = false
      })
      this.$store.commit('toggleLoader')
    }
  }
}
