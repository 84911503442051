
import Application from '../../services/App/Application'
import ClubService from '../../services/Enitities/ClubService'
import ClubMemberService from '../../services/Enitities/ClubMemberService'

export default {
  name: 'ClubLogo',
  data ():unknown {
    return {
      club: ClubService.get(),
      logoImage: ''
    }
  },
  created ():void {
    this.logoImage = Application.getApiUrl() + '/resource/' + this.club.id + '/logo.png'
    this.clubMember = ClubMemberService.getCurrentMember()
  }
}
