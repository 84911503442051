
import { ClubMemberRights, GroupMemberRights, GroupMemberTypes, GroupTypes } from '@/models/Enum'
import GroupMemberService from '@/services/Enitities/GroupMemberService'
import Authorization from '@/services/App/Authorization'
import GroupService from '@/services/Enitities/GroupService'
import { Group } from '@/models/Group'
import { GroupMember } from '@/models/GroupMember'

export default {
  name: 'MemberGroups',
  props: {
    clubMember: {
      required: true
    }
  },
  data ():unknown {
    return {
      GroupMemberRights,
      Authorization,
      ClubMemberRights,
      GroupMemberTypes,
      dialog: false,
      Groups: [],
      newGroupMember: {
        rights: [],
        type: 1
      }
    }
  },
  mounted ():void {
    this.loadAvailableGroups()
  },
  methods: {
    async deleteMember (Group:Group, GroupMember:GroupMember):Promise<void> {
      this.$store.commit('toggleLoader')
      await (new GroupMemberService()).deleteMember(Group, GroupMember)
      this.$store.commit('addMessage', {
        text: 'Member removed from Group'
      })
      this.clubMember.group_members = this.clubMember.group_members.filter(tm => tm.id !== GroupMember.id)
      await this.loadAvailableGroups()
      this.$store.commit('toggleLoader')
    },
    async saveMember (Group:Group, GroupMember:GroupMember):Promise<void> {
      this.$store.commit('toggleLoader')
      await (new GroupMemberService()).saveMember(Group, GroupMember)
      this.$store.commit('addMessage', {
        text: 'Member changed'
      })
      this.$store.commit('toggleLoader')
    },
    addToGroup: async function (Group:Group):Promise<void> {
      this.$store.commit('toggleLoader')
      const response = await (new GroupMemberService()).addMembers(Group, this.clubMember, this.newGroupMember.type, this.newGroupMember.rights)
      if (response.ok) {
        const GroupMember = await response.json()
        this.clubMember.group_members.push(GroupMember)
        await this.loadAvailableGroups()
        this.$store.commit('addMessage', {
          text: 'Member added to ' + Group.name
        })
        this.newGroupMember = {
          rights: [],
          type: 1
        }
      }
      this.$store.commit('toggleLoader')
    },
    async loadAvailableGroups ():Promise<void> {
      const AlreadyInGroups = []
      this.Groups = []
      for (const GroupMember of this.clubMember.group_members) {
        AlreadyInGroups.push(GroupMember.group.id)
      }
      for (const Group of await (new GroupService()).getAll()) {
        if (!AlreadyInGroups.includes(Group.id)) {
          this.Groups.push(Group)
        }
      }
    },
    getMemberTypes (group:Group): Array<{text:string, value:number}> {
      if (group.type === GroupTypes.Commission) {
        return [{ text: this.$t('groupMembers.types.chairmen'), value: GroupMemberTypes.Chairmen }, { text: this.$t('groupMembers.types.member'), value: GroupMemberTypes.Member }]
      }
      if (group.type === GroupTypes.Team) {
        return [{ text: this.$t('groupMembers.types.player'), value: GroupMemberTypes.Player }, { text: this.$t('groupMembers.types.coach'), value: GroupMemberTypes.Coach }, { text: this.$t('groupMembers.types.staff'), value: GroupMemberTypes.Staff }]
      }
    }
  }
}
