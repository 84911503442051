
import GroupMemberService from '@/services/Enitities/GroupMemberService'
import { GroupMemberTypes } from '@/models/Enum'
import MemberCard from '@/components/Member/MemberCard.vue'
import moment from 'moment'

export default {
  name: 'GroupMembersOverview',
  components: { MemberCard },
  data ():unknown {
    return {
      moment,
      loading: true,
      members: [],
      startDate: moment().startOf('year').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      GroupMemberTypes
    }
  },
  props: {
    group: {
      required: true
    }
  },
  methods: {
    async getMembers ():Promise<void> {
      this.loading = true
      const members = await (new GroupMemberService()).getMembers(this.group, this.startDate, this.endDate)
      this.members = members.sort((a, b) => (a.club_member.first_name > b.club_member.first_name) ? 1 : -1)
      this.loading = false
    }
  },
  mounted ():void {
    this.getMembers()
  },
  watch: {
    group ():void {
      this.getMembers()
    }
  }
}
