
import ClubMemberService from '@/services/Enitities/ClubMemberService'
import Application from '@/services/App/Application'

export default {
  name: 'MemberiCal',
  data ():unknown {
    return {
      dialog: false,
      icalUrlClubMember: '',
      clubMember: ClubMemberService.getCurrentMember()
    }
  },
  methods: {
    copyClubMember ():void {
      this.$refs.clubMemberUrl.focus()
      document.execCommand('copy')
      this.dialog = false
      this.$store.commit('addMessage', {
        text: 'Copied!'
      })
    }
  },
  created ():void {
    this.icalUrlClubMember = Application.getApiUrl() + '/ical/v1/member/' + this.clubMember.id + '/' + this.clubMember.i_cal_token + '.ics'
  }
}
