
import moment from 'moment'
import { EventTypes } from '@/models/Enum'

export default {
  name: 'EventDetails',
  props: {
    groupEvent: {
      required: true
    }
  },
  methods: {
    goToMaps ():void {
      window.open('https://maps.google.com/?q=' + this.groupEvent.location)
    }
  },
  data ():unknown {
    return {
      EventTypes,
      start: moment(this.groupEvent.start),
      end: moment(this.groupEvent.end),
      gatheringTime: moment(this.groupEvent.gathering_time),
      inThePast: (new Date(this.groupEvent.start)) < (new Date())
    }
  }
}
