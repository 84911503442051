import { Api } from '@/services/API/Api'
import { GroupEvent } from '@/models/GroupEvent'
import { GroupEventInvite } from '@/models/GroupEventInvite'
import { ClubMember } from '@/models/ClubMember'
import { GroupEventJob } from '@/models/GroupEventJob'

class GroupEventInviteService {
  private api: Api

  constructor () {
    this.api = new Api()
  }

  public async declineEvent (invite:GroupEventInvite, reason:string):Promise<{jobs:GroupEventJob[]}|undefined> {
    const response = await this.api.request('groupEventInvite/' + invite.id + '/decline', 'POST', {
      reason: reason
    })
    if (response.ok) {
      return await response.json()
    }
  }

  public async acceptEvent (invite:GroupEventInvite):Promise<{jobs:GroupEventJob[]}|undefined> {
    const response = await this.api.request('groupEventInvite/' + invite.id + '/accept', 'POST')
    if (response.ok) {
      return await response.json()
    }
  }

  public async addEvent (clubMember:ClubMember, teamEvent:GroupEvent):Promise<GroupEventInvite|undefined> {
    const response = await this.api.request('event/' + teamEvent.id + '/addInvite', 'POST', {
      clubMemberId: clubMember.id
    })
    if (response.ok) {
      return await response.json()
    }
  }

  public async openInvitation (teamEvent:GroupEvent, status:number, declinedReason:string|null):Promise<GroupEventInvite|undefined> {
    const response = await this.api.request('event/' + teamEvent.id + '/openInvite', 'POST', {
      status: status,
      declined_reason: declinedReason
    })
    if (response.ok) {
      return await response.json()
    }
  }

  public async deleteInvitation (invite:GroupEventInvite):Promise<Response> {
    return this.api.request('groupEventInvite/' + invite.id + '/remove', 'POST')
  }
}

export default GroupEventInviteService
