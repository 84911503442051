
import GroupNewsService from '@/services/Enitities/GroupNewsService'
import { GroupMemberRights, NewsTransparencyLevels } from '@/models/Enum'
import Authorization from '@/services/App/Authorization'

export default {
  name: 'EditNewsItem',
  props: {
    news: {
      required: true
    },
    group: {
      required: true
    }
  },
  data (): unknown {
    return {
      Authorization,
      GroupMemberRights,
      NewsTransparencyLevels,
      dialog: false,
      valid: false
    }
  },
  methods: {
    validate (): void {
      this.$refs.form.validate()
    },
    async savePost (e:Event):Promise<void> {
      e.preventDefault()
      this.validate()
      if (this.valid) {
        this.$store.commit('toggleLoader')
        const response = await (new GroupNewsService()).updateNews(this.group.id, this.news.id, this.news.title, this.news.description, null, this.news.transparency_level)
        this.$store.commit('toggleLoader')
        if (response) {
          this.$store.commit('addMessage', {
            text: 'Job saved'
          })
          this.dialog = false
        }
      }
    }
  }
}
