
import { LocalSettings, LocalSettingNames } from '@/services/App/LocalSettings'
import Application from '@/services/App/Application'
import UserService from '@/services/Enitities/UserService'
import Authentication from '@/services/App/Authentication'
import { WebPush } from '@/services/App/WebPush'

export default {
  name: 'ProfileSettings',
  data ():unknown {
    return {
      WebPush,
      settings: {
        language: this.$i18n.locale,
        theme: LocalSettings.get(LocalSettingNames.Theme) === undefined ? 0 : LocalSettings.get(LocalSettingNames.Theme)
      }
    }
  },
  methods: {
    async changeLanguage ():Promise<void> {
      this.$store.commit('toggleLoader')
      const response = await UserService.updateSetting('language', this.settings.language)
      if (response.ok) {
        this.$i18n.locale = this.settings.language
        const user = Authentication.getUser()
        user.preferred_language = this.settings.language
        Authentication.setUser(user)
      }
      this.$store.commit('toggleLoader')
    },
    changeTheme ():void {
      LocalSettings.set(LocalSettingNames.Theme, this.settings.theme)
      this.$vuetify.theme.dark = Application.IsDarkMode()
    }
  }
}
