
import InviteSelection from '@/components/Event/EventInvite/InviteSelection.vue'
import OpenInviteSelection from '@/components/Event/EventInvite/OpenInviteSelection.vue'

export default {
  name: 'EventOpenInvite',
  components: { OpenInviteSelection, InviteSelection },
  props: {
    groupEvent: {
      required: true
    }
  },
  data ():unknown {
    return {
      openInviteLoading: false,
      inThePast: (new Date(this.groupEvent.start)) < (new Date())
    }
  }
}
