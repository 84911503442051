
import moment from 'moment'

export default {
  name: 'NewsCard',
  props: {
    groupNews: {
      required: true
    },
    group: {
      required: true
    }
  },
  data (): unknown {
    return {
      moment
    }
  }
}
