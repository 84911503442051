
import ClubLogo from '@/components/Club/ClubLogo.vue'
import ClubMemberService from '@/services/Enitities/ClubMemberService'
import CurrentGroupList from '@/components/Main/Dashboard/CurrentGroupList.vue'

export default {
  name: 'Sidebar',
  components: { CurrentGroupList, ClubLogo },
  data (): unknown {
    return { clubMember: ClubMemberService.getCurrentMember() }
  }
}
