
import ClubService from '@/services/Enitities/ClubService'
import UpdateSw from '@/components/Main/Dashboard/updateSw.vue'
import BottomNavigation from '@/components/Main/Dashboard/BottomNavigation.vue'
import Messages from '@/components/Main/Dashboard/Messages.vue'
import ClubMemberService from '@/services/Enitities/ClubMemberService'
import Authentication from '@/services/App/Authentication'
import TopBar from '@/components/Main/Dashboard/TopBar.vue'
import Sidebar from '@/components/Main/Dashboard/Sidebar.vue'

export default {
  name: 'Dashboard',
  components: { Sidebar, TopBar, Messages, BottomNavigation, UpdateSw },
  data: (): unknown => ({
    showActiveM: false,
    drawer: false,
    club: {},
    toolbarStyle: {},
    clubMember: ClubMemberService.getCurrentMember(),
    user: Authentication.getUser()
  }),
  async created (): Promise<void> {
    this.club = ClubService.get()
    this.toolbarStyle = {
      'background-color': this.club.background_color
    }
    if (this.clubMember) {
      if (this.clubMember?.initials !== this.user.initials && this.clubMember.initials) {
        this.showActiveM = true
      }
    }
  },
  methods: {
    isMobile ():boolean {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    }
  }
}
