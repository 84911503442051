
import ClubMemberService from '@/services/Enitities/ClubMemberService'
import MemberCard from '@/components/Member/MemberCard.vue'
import { GroupMemberTypes, GroupTypes } from '@/models/Enum'

export default {
  name: 'MemberOverview',
  components: { MemberCard },
  props: {
    membersOnly: {
      default: true
    },
    guestOnly: {
      default: false
    }
  },
  data ():unknown {
    return {
      GroupMemberTypes,
      GroupTypes,
      page: 1,
      members: [],
      query: '',
      amount: 10,
      pageAmount: 5,
      loading: false,
      timeout: null,
      headers: [
        {
          text: this.$t('clubMember.firstname') + ' ' + this.$t('clubMember.lastname').toLowerCase(),
          value: 'profile',
          sortable: false
        },
        {
          text: 'Account',
          value: 'account',
          sortable: false
        },
        {
          text: 'Parents',
          value: 'parents',
          sortable: false
        },
        {
          text: 'Age',
          value: 'age',
          sortable: false
        },
        {
          text: this.$t('group.groups'),
          value: 'groups',
          sortable: false
        }
      ]
    }
  },
  methods: {
    async loadMembers () :Promise<void> {
      this.loading = true
      this.pageAmount = 5
      this.members = []
      const response = await (new ClubMemberService()).searchMember(
        this.query,
        (this.page - 1) * this.amount,
        this.amount,
        this.membersOnly, this.guestOnly)
      if (response) {
        this.members = response.result
        this.pageAmount = Math.ceil(parseInt(response.amount) / this.amount)
      }
      this.loading = false
    }
  },
  watch: {
    async query (val:string):Promise<void> {
      if (val === null) return

      clearTimeout(this.timeout)

      // eslint-disable-next-line @typescript-eslint/no-this-alias
      var self = this
      this.timeout = setTimeout(async function () {
        // Items have already been requested
        self.loading = true
        self.page = 1

        await self.loadMembers()

        self.loading = false
      }, 1000)
    }
  },
  mounted ():void {
    this.loadMembers()
  }
}
