import { Api } from '@/services/API/Api'
import { GroupNews } from '@/models/GroupNews'
import { Group } from '@/models/Group'
import { NewsTransparencyLevels } from '@/models/Enum'

export class GroupNewsService {
  api: Api

  constructor () {
    this.api = new Api()
  }

  public async createNews (title: string, description: string, group: Group): Promise<GroupNews> {
    const creatNewsRequest = await this.api.request('group/' + group.id + '/news/create', 'POST', {
      title: title,
      description: description
    })
    return await creatNewsRequest.json() as GroupNews
  }

  public async getNewsFromGroup (group:Group): Promise<GroupNews[]> {
    const creatNewsRequest = await this.api.request('group/' + group.id + '/news', 'GET')
    return await creatNewsRequest.json() as GroupNews[]
  }

  public async getNewsPost (group:Group, newsId:string): Promise<GroupNews> {
    const creatNewsRequest = await this.api.request('group/' + group.id + '/news/' + newsId, 'GET')
    return await creatNewsRequest.json() as GroupNews
  }

  public async updateNews (groupId: string, newsId: string, title: string = null, description: string = null, content: string = null, newsTransparencyLevels:NewsTransparencyLevels): Promise<GroupNews> {
    const request = await this.api.request('group/' + groupId + '/news/' + newsId, 'POST', {
      title: title,
      description: description,
      content: content,
      level: newsTransparencyLevels
    })
    return await request.json() as GroupNews
  }

  public async publishNews (groupId: string, newsId: string, newsTransparencyLevels:NewsTransparencyLevels): Promise<GroupNews> {
    const request = await this.api.request('group/' + groupId + '/news/' + newsId + '/publish', 'POST', {
      level: newsTransparencyLevels
    })
    return await request.json() as GroupNews
  }
}

export default GroupNewsService
