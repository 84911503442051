
import EventCard from '@/components/Event/EventDialog/EventCard.vue'
import Authorization from '@/services/App/Authorization'
import { GroupMemberRights } from '@/models/Enum'
import CreateGroupEvent from '@/components/Group/Event/CreateGroupEvent.vue'
import GroupEventService from '@/services/Enitities/GroupEventService'

export default {
  name: 'groupEventOverview',
  components: { CreateGroupEvent, EventCard },
  props: {
    group: {
      required: true
    }
  },
  data ():unknown {
    return {
      Authorization,
      GroupMemberRights,
      loading: true,
      eventType: 'Upcoming',
      events: [],
      totalEvents: 0,
      currentPage: 1,
      pageAmount: 5,
      toggleInvited: false
    }
  },
  methods: {
    async getEvents ():Promise<void> {
      this.loading = true
      let response
      const amountPP = 15
      switch (this.eventType) {
        case 'Upcoming':
        default:
          response = await (new GroupEventService()).getEvents(this.group, amountPP, (this.currentPage - 1) * amountPP, 'ASC', this.toggleInvited)
          break
        case 'Past':
          response = await (new GroupEventService()).getEvents(this.group, amountPP, (this.currentPage - 1) * amountPP, 'DESC', this.toggleInvited)
          break
      }
      this.events = response.events
      this.totalEvents = response.amount
      this.pageAmount = Math.ceil(parseInt(this.totalEvents) / amountPP)
      this.loading = false
    },
    toggleInvitedEvents ():void {
      this.getEvents()
      this.toggleInvited = !this.toggleInvited
    }
  },
  mounted ():void {
    this.getEvents()
  },
  watch: {
    group ():void {
      this.getEvents()
    }
  }
}
