import { LocaleMessageObject } from 'vue-i18n'

export default {
  save: 'Save',
  cancel: 'Cancel',
  edit: 'Edit',
  delete: 'Delete',
  create: 'Create',
  add: 'Add',
  search: 'Search',
  ok: 'Ok',
  enable: 'Enable',
  done: 'Done',
  understand: 'I understand',
  requiredField: 'This is required',
  nothingFound: 'Nothing found',
  actionNotUndo: 'This action can not be undone',
  passwordLength: 'The password needs to be at least 8 characters long',
  emailValid: 'The E-mail address needs to be valid',
  title: 'Title',
  description: 'Description',
  event: {
    more: 'More events',
    create: 'Create new event',
    event: 'Event',
    events: 'Events',
    attendance: 'Attendance',
    yourEvents: 'Your upcoming events',
    notFound: 'No events have been found',
    title: 'Title',
    description: 'Description',
    location: 'Location',
    duration: 'Duration',
    durationFormat: 'In hours',
    startTime: 'Start time',
    upcoming: 'Upcoming',
    past: 'Past',
    options: {
      gathering: {
        name: 'Gathering options'
      },
      advanced: {
        name: 'Advanced options',
        privateEvent: 'Invited members only',
        clubEvent: 'Club event',
        privateEventDescription: 'Event is only visible for invited members',
        clubEventDescription: 'Event is displayed to all club members, participation can be asked with open invitation'
      },
      invites: {
        name: 'Invite options',
        open: 'Open attendance, all Club members'
      },
      cancel: {
        name: 'Cancel event',
        description: 'Cancel this event'
      },
      delete: {
        name: 'Delete event',
        description: 'Delete this event'
      },
      repeat: {
        name: 'Repeat event',
        until: 'Repeat event until ',
        amount: 'repeat event amount'
      }
    },
    type:
      {
        name: 'Type',
        game: 'Game',
        practice: 'Practice',
        other: 'Other',
        clubEvent: 'Club event',
        canceled: 'Canceled'
      },
    gathering: {
      time: 'Gathering time',
      location: 'Gathering location'
    },
    invite: {
      name: 'Invite',
      decline: 'Decline',
      reason: 'Reason',
      areYouComing: 'Are you coming?'
    }
  },
  groupMembers: {
    member: 'Member',
    members: 'Members',
    type: 'Member type',
    types: {
      coaches: 'Coaches',
      chairmen: 'Chairmen',
      member: 'Member',
      coach: 'Coach',
      staff: 'Staff',
      player: 'Player',
      players: 'Players'
    },
    rights: {
      GROUP_EVENTS: 'Group events',
      GROUP_EVENT_JOBS: 'Manage group tasks'
    }
  },
  task: {
    edit: 'Edit task',
    title: 'Title',
    description: 'Description',
    yourTasks: 'Your tasks',
    task: 'Task',
    tasks: 'Tasks',
    open: 'Open tasks',
    takeTask: 'Take task',
    notFound: 'No tasks found',
    new: 'New task',
    reminderEvent: 'Reminder, member has tasks this event.'
  },
  security: {
    login: {
      email: 'E-mail',
      password: 'Password',
      login: 'Login',
      loginError: 'Login failed, Email and password combination are not found'
    },
    forgotPassword: {
      name: 'Forgot password'
    },
    registrationValidation: {
      title: 'Registered as guest',
      description: 'You are registered as guest by ',
      next: 'The admin has been notified, This person can add you to right team'
    },
    resetPassword: {
      title: 'Change your password',
      requestReset: 'Request new password'
    },
    registration: {
      ClubMember: 'Register as a club member',
      doYouHaveAnAccount: 'Do you have an account?',
      stayUpToDate: 'Stay up to date with your club',
      CreateAnAccount: 'Create an account',
      CreateAccount: 'Create account',
      Type: 'Account type',
      YourDetails: 'Your details',
      AddAnChild: 'Add an child',
      RemoveAnChild: 'remove child',
      Children: 'Children',
      ConnectedMembers: 'Connected club members',
      Child: 'Child',
      ChildAccount: 'Do you have children that are club member?',
      ChildAccountDetail: 'Add them here to your account',
      ChildCreateAccount: 'Create an account for you child. This is optional',
      ChildAdd: 'Add your child to Keeva.',
      ChildInformation: 'Information about your child',
      SomeInformation: 'Some information',
      EmailValidation: 'Email validation',
      EmailValidationDetails: 'An Email ahs been send to you, follow the link in the email to activate your account.',
      done: 'Your registration is complete',
      TermsOfService: 'Terms of service',
      TermsOfServiceAgreement: 'I accept the terms of service of ',
      PrivacyPolicy: 'Privacy Policy ',
      PrivacyPolicyAgreement: 'I accept the privacy policy of ',
      YourTeams: 'What is your team?',
      Types: {
        Title: 'Who are you?',
        ClubMember: 'I am a club member',
        Parent: 'I am a parent, My child is a club member',
        Guest: 'I am a fan'
      }
    }
  },
  clubMember: {
    name: 'Club member',
    contactInformation: 'Contact information',
    firstname: 'Firstname',
    lastname: 'Lastname',
    email: 'Email',
    password: 'Password',
    passwordValidation: 'Password Validation',
    birthdate: 'Birthdate',
    mobileNumber: 'Mobile number',
    years: 'Years',
    profile: {
      name: 'Personal information',
      logout: 'Sign-out',
      settings: {
        name: 'Settings',
        user: {
          name: 'User',
          language: 'Language'
        },
        device: {
          name: 'Device',
          theme: 'Theme',
          notification: 'Notifications',
          notificationDescription: 'Enable push notifications to stay up to date with you activities.'
        }
      },
      calendarSync: {
        title: 'Sync your events',
        description: 'Your personal events, add them to your personal calendar',
        copy: 'Copy'
      }
    },
    connectMembers: {
      name: 'Parents',
      types: {
        type: 'Type',
        child: 'Child',
        parent: 'Parent',
        typeDescription: 'Witch type will the connected member be?'
      },
      AddNewConnection: 'Add a parent',
      ExistingConnection: 'Existing parents',
      NoExistingConnection: 'No existing parents found'
    },
    guest: {
      name: 'Club guest',
      move: 'Guest to member'
    },
    rights: {
      name: 'Club rights',
      CLUB_MEMBER_MANAGER: 'Club member management',
      CLUB_TEAM_MANAGER: 'Team management',
      CLUB_COMMISSION_MANAGER: 'Commission management',
      CLUB_MANAGER: 'Main admin'
    }
  },
  group: {
    group: 'Group',
    groups: 'Groups',
    availableGroups: 'Available groups',
    memberOfGroups: 'Member of groups',
    name: 'Name',
    type: 'Type',
    typeHelp: 'A team has game and practices, an commission can only plan other events',
    clubEvents: 'Group can create club events',
    clubEventsHelp: 'Club events a presented to all club members and their participation is asked',
    new: 'Create a new group',
    types: {
      teams: 'Teams',
      team: 'Team',
      commission: 'Commission',
      commissions: 'Commissions'
    }
  },
  serviceWorker: {
    update: {
      name: 'New version of Keeva',
      description: 'There is a new version available, update Keeva to use the lastest version.',
      update: 'Update'
    },
    install: {
      name: 'Install Keeva',
      description: 'Install Keeva as an app on your phone for easier use.',
      descriptioniOs: 'Install Keeva as an app on your phone for easier use. Follow these steps',
      addToHomeScreen: 'Add to homescreen',
      install: 'Install Keeva'
    }
  },
  admin: {
    settings: 'Club settings',
    panel: 'Admin panel'
  },
  navigation: {
    myGroups: 'My Groups',
    club: 'Club',
    dashboard: 'Dashboard'
  },
  calender: {
    clubCalendar: 'Club calender',
    clubCalenderDescription: 'All the events listed by all teams and commissions from '
  },
  transparency: 'Transparency',
  news: {
    name: 'News',
    transparency: {
      0: 'For group members',
      1: 'For club members',
      2: '',
      3: 'Public'
    }
  }
} as LocaleMessageObject
