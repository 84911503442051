import { render, staticRenderFns } from "./NewsCard.vue?vue&type=template&id=427e9649&scoped=true&"
import script from "./NewsCard.vue?vue&type=script&lang=ts&"
export * from "./NewsCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "427e9649",
  null
  
)

export default component.exports