
import { EventInviteStatusTypes, GroupMemberRights } from '@/models/Enum'
import GroupEventInviteService from '@/services/Enitities/GroupEventInviteService'
import ClubMemberService from '@/services/Enitities/ClubMemberService'
import Authorization from '@/services/App/Authorization'
import ClubMemberStatusSelection from '@/components/Event/ClubMemberStatusSelection.vue'
import JobReminderDialog from '@/components/Event/EventInvite/JobReminderDialog.vue'
import moment from 'moment'

export default {
  name: 'InviteSelection',
  components: { JobReminderDialog, ClubMemberStatusSelection },
  props: {
    groupEventInvite: {
      required: true
    },
    disabled: {
      default: false
    },
    group: {
      required: true
    },
    groupEvent: {
      required: true
    }
  },
  watch: {
    async status (): Promise<void> {
      this.loading = true
      if (this.status !== this.groupEventInvite.status) {
        if (this.status === EventInviteStatusTypes.Accepted) {
          await this.acceptInvite()
        }
        if (this.status === EventInviteStatusTypes.Declined) {
          this.declineDialog = true
        }
      }
      this.groupEventInvite.status = this.status
      this.loading = false
    }
  },
  data ():unknown {
    return {
      reason: '',
      tooltip: false,
      upcomingEvent: moment(this.groupEvent.start).unix() > moment().add(2, 'days').unix(),
      reasonRules: [
        (v):boolean|string => v.length < 255 || 'Reason can only be 255 characters'
        // (v):boolean|string => (!this.upcomingEvent && !!v) || this.$t('requiredField')
      ],
      valid: false,
      declineDialog: false,
      loading: false,
      status: this.groupEventInvite.status,
      eventJobs: [],
      EventInviteStatusTypes,
      Authorization,
      GroupMemberRights
    }
  },
  methods: {
    InviteIsForCurrentMember ():boolean {
      if ('club_member' in this.groupEventInvite) {
        return ClubMemberService.getCurrentMember()?.id === this.groupEventInvite.club_member.id
      }
      return true
    },
    validate ():void {
      this.$refs.form.validate()
    },
    async acceptInvite ():Promise<void> {
      const response = await (new GroupEventInviteService()).acceptEvent(this.groupEventInvite)
      this.eventJobs = response.jobs
      this.groupEventInvite.status = EventInviteStatusTypes.Accepted
      this.groupEventInvite.decline_reason = null
      this.$store.commit('addMessage', {
        text: 'Status changed'
      })
    },
    async declineInvite (e:Event):Promise<void> {
      e.preventDefault()
      this.validate()
      if (this.valid) {
        this.loading = true
        this.declineDialog = false
        const response = await (new GroupEventInviteService()).declineEvent(this.groupEventInvite, this.reason)
        this.eventJobs = response.jobs
        this.groupEventInvite.status = EventInviteStatusTypes.Declined
        this.$store.commit('addMessage', {
          text: 'Status changed'
        })
        this.groupEventInvite.decline_reason = this.reason
        this.loading = false
      }
    }
  }
}
