
import UserService from '@/services/Enitities/UserService'
import { ClubMemberRights } from '@/models/Enum'
import ClubService from '@/services/Enitities/ClubService'

export default {
  name: 'RegistrationValidation',
  data ():unknown {
    return {
      guestDialog: false
    }
  },
  async created ():Promise<void> {
    this.$store.commit('toggleLoader')
    this.club = ClubService.get()
    const params = new URLSearchParams(window.location.search)
    if (params.has('validation')) {
      const response = await UserService.validateToken(params.get('validation'))
      if (response.ok) {
        const clubMember = await response.json()
        if (clubMember.rights.includes(ClubMemberRights.CLUB_MEMBER)) {
        } else {
          // No member is found, new guest is added
          this.guestDialog = true
        }
      }
    }
    this.$store.commit('toggleLoader')
  }
}
