
import Dashboard from '@/components/Main/Dashboard.vue'
import ClubLogo from '@/components/Club/ClubLogo.vue'
import GroupService from '@/services/Enitities/GroupService'
import CreateNewsItem from '@/components/News/CreateNewsItem.vue'
import GroupNewsService from '@/services/Enitities/GroupNewsService'
import NewsItem from '@/components/News/NewsCard.vue'

export default {
  name: 'GroupNews',
  components: { NewsItem, CreateNewsItem, ClubLogo, Dashboard },
  data (): unknown {
    return {
      group: null,
      tab: null,
      loading: false,
      newsItems: []
    }
  },
  watch: {
    async $route (): Promise<void> {
      await this.load()
    }
  },
  async created (): Promise<void> {
    await this.load()
  },
  methods: {
    async load (): Promise<void> {
      this.$store.commit('toggleLoader')
      this.group = await (new GroupService()).get(this.$route.params.id)
      this.$store.commit('toggleLoader')
      this.loading = true;
      (new GroupNewsService()).getNewsFromGroup(this.group).then((response) => {
        this.newsItems = response
        this.loading = false
      })
    }
  }
}
