
import Authorization from '@/services/App/Authorization'
import { ClubMemberRights } from '@/models/Enum'

export default {
  name: 'MemberCard',
  props: {
    member: {
      required: true
    },
    groupMember: {
      required: false,
      default: null
    }
  },
  data ():unknown {
    return {
      currentMemberIsAdmin: Authorization.HasCurrentClubMemberRight(ClubMemberRights.CLUB_MEMBER_MANAGER),
      Authorization,
      ClubMemberRights
    }
  }
}
