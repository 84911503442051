
import { ClubMemberRights } from '@/models/Enum'
import ClubMemberService from '@/services/Enitities/ClubMemberService'
import Authorization from '@/services/App/Authorization'

export default {
  name: 'MemberRights',
  props: {
    clubMember: {
      required: true
    }
  },
  data ():unknown {
    return {
      ClubMemberRights,
      Authorization,
      dialog: false
    }
  },
  methods: {
    async saveRights ():Promise<void> {
      this.$store.commit('toggleLoader')
      await (new ClubMemberService()).updateMember(this.clubMember.id, this.clubMember)
      this.$store.commit('addMessage', {
        text: 'Member changed'
      })
      this.$store.commit('toggleLoader')
    }
  }
}
