import { LocalStorage, StorageNames } from '@/services/App/LocalStorage'

export enum onTimeDialog{
  Notification = 'notification',
  ClubMemberRegistration = 'ClubMemberRegistration'
}

export class onTimeDialogs {
  static hasBeenShown (dialog:onTimeDialog):boolean {
    const dialogs = onTimeDialogs.Start()
    if (Array.isArray(dialogs)) {
      return dialogs.includes(dialog)
    }
    return false
  }

  static Start ():onTimeDialog[] {
    const dialogs = LocalStorage.get(StorageNames.OnTimeDialogs)
    if (!dialogs) {
      LocalStorage.set(StorageNames.OnTimeDialogs, [])
      return []
    }
    return dialogs as onTimeDialog[]
  }

  static addSeenDialog (dialog:onTimeDialog):void {
    let dialogs = onTimeDialogs.Start()
    if (!Array.isArray(dialogs)) {
      dialogs = []
    }
    dialogs.push(dialog)
    LocalStorage.set(StorageNames.OnTimeDialogs, dialogs)
  }
}
