
import ClubMemberService from '@/services/Enitities/ClubMemberService'
import MainActionCard from '@/components/Main/MainActionCard.vue'

export default {
  name: 'ClubMembersStates',
  components: { MainActionCard },
  data ():unknown {
    return {
      totalMembers: 0,
      clubMembers: 0,
      guests: 0,
      duplicates: 0,
      invites: 0
    }
  },
  async created (): Promise<void> {
    const response = await (new ClubMemberService()).getCount()
    const data = await response.json()
    this.totalMembers = data.guests + data.members
    this.clubMembers = data.members
    this.guests = data.guests
    this.duplicates = data.duplicates
  }
}
