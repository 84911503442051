
import ClubMemberService from '@/services/Enitities/ClubMemberService'
import Authorization from '@/services/App/Authorization'
import { ClubMemberRights } from '@/models/Enum'

export default {
  name: 'SearchClubMember',
  data ():unknown {
    return {
      Authorization,
      ClubMemberRights,
      members: [this.value],
      selectedMember: this.value,
      searchingMember: false,
      search: null,
      timeout: null,
      rules: [
        (v):boolean|string => (this.required ? !!v : true) || this.$t('requiredField')
      ]
    }
  },
  props: {
    value: {
      type: Object || Array
    },
    membersOnly: {
      type: Boolean,
      default: true
    },
    guestOnly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    search (val:string):void {
      if (this.searchingMember || this.search === null) return

      clearTimeout(this.timeout)

      // eslint-disable-next-line @typescript-eslint/no-this-alias
      var self = this
      this.timeout = setTimeout(async function () {
        // Items have already been requested
        self.searchingMember = true

        val && val !== self.selectedMember && await self.searchMembers(val)

        self.searchingMember = false
      }, 1000)
    },
    selectedMember ():void {
      this.$emit('input', this.selectedMember)
      this.search = null
    }
  },
  methods: {
    async searchMembers (search:string):Promise<void> {
      const response = await (new ClubMemberService()).searchMember(search, 0, 5, this.membersOnly, this.guestOnly)
      if ('result' in response) {
        this.members = response.result
      }
    }
  }
}
