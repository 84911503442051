
import ClubService from '@/services/Enitities/ClubService'
import LoginCard from '@/components/Security/LoginCard.vue'
import RegistrationValidation from '@/components/Security/RegistrationValidation.vue'
import Application from '@/services/App/Application'

export default {
  name: 'Login',
  components: { RegistrationValidation, LoginCard },
  data ():unknown {
    return {
      club: {},
      backgroundStyle: {},
      logoImage: ''
    }
  },
  created ():void {
    this.club = ClubService.get()
    this.backgroundStyle = {
      background: 'center center/cover no-repeat',
      'background-color': this.club.background_color,
      'background-image': 'url(' + Application.getApiUrl() + '/resource/' + this.club.id + '/background.svg)'
    }
    this.logoImage = Application.getApiUrl() + '/resource/' + this.club.id + '/logo.png'
  }
}
