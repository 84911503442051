
import GroupEventService from '@/services/Enitities/GroupEventService'

export default {
  name: 'EventDelete',
  props: {
    groupEvent: {
      required: true
    }
  },
  data ():unknown {
    return {
      loading: false
    }
  },
  methods: {
    async deleteEvent ():Promise<void> {
      this.loading = true
      if ((await (new GroupEventService()).DeleteEvent(this.groupEvent)).ok) {
        this.$store.commit('addMessage', {
          text: 'Event deleted'
        })
        await this.$router.push({ name: 'Group', params: { id: this.groupEvent.group.id } })
      }
      this.loading = false
    }
  }
}
