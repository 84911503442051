
import { EventInviteStatusTypes, GroupMemberRights } from '@/models/Enum'
import GroupEventInviteService from '@/services/Enitities/GroupEventInviteService'
import Authorization from '@/services/App/Authorization'
import ClubMemberStatusSelection from '@/components/Event/ClubMemberStatusSelection.vue'

export default {
  name: 'OpenInviteSelection',
  components: { ClubMemberStatusSelection },
  props: {
    disabled: {
      default: false
    },
    group: {
      required: true
    },
    groupEvent: {
      required: true
    }
  },
  watch: {
    async status (): Promise<void> {
      this.loading = true
      if (this.status === EventInviteStatusTypes.Accepted) {
        await this.openInvite()
      }
      if (this.status === EventInviteStatusTypes.Declined) {
        this.declineDialog = true
      }
      this.loading = false
    }
  },
  data ():unknown {
    return {
      tooltip: false,
      reasonRules: [
        (v):boolean|string => v.length < 255 || 'Reason can only be 255 characters'
      ],
      valid: false,
      declineDialog: false,
      loading: false,
      status: EventInviteStatusTypes.Pending,
      decline_reason: '',
      EventInviteStatusTypes,
      Authorization,
      GroupMemberRights
    }
  },
  methods: {
    validate ():void {
      this.$refs.form.validate()
    },
    async openInvite ():Promise<void> {
      this.loading = true
      this.groupEvent.member_invite = await (new GroupEventInviteService()).openInvitation(this.groupEvent, this.status, this.decline_reason)
      this.loading = false
    },
    async declineInvite (e:Event):Promise<void> {
      e.preventDefault()
      this.validate()
      if (this.valid) {
        this.loading = true
        this.declineDialog = false
        this.groupEvent.member_invite = await (new GroupEventInviteService()).openInvitation(this.groupEvent, this.status, this.decline_reason)
        this.$store.commit('addMessage', {
          text: 'Status changed'
        })
        this.loading = false
      }
    }
  }
}
