
import { EventInviteStatusTypes, GroupMemberRights } from '@/models/Enum'
import Authorization from '@/services/App/Authorization'
import EventJobSelection from '@/components/Event/EventJobs/EventJobSelection.vue'
import GroupEventJobService from '@/services/Enitities/GroupEventJobService'
import EventJobEditDialog from '@/components/Event/EventJobs/EventJobEditDialog.vue'
import ClubMemberService from '@/services/Enitities/ClubMemberService'
import moment from 'moment'
import MemberCard from '@/components/Member/MemberCard.vue'
import { GroupEventJob } from '@/models/GroupEventJob'

export default {
  name: 'EventJobsItem',
  components: { MemberCard, EventJobEditDialog, EventJobSelection },
  props: {
    job: {
      required: true
    },
    groupEvent: {
      required: true
    },
    group: {
      required: true
    }
  },
  data ():unknown {
    return {
      start: moment(this.groupEvent.start),
      end: moment(this.groupEvent.end),
      Authorization,
      GroupMemberRights,
      EventInviteStatusTypes,
      inThePast: (new Date(this.groupEvent.start)) < (new Date())
    }
  },
  methods: {
    JobIsForCurrentMember (job:GroupEventJob):boolean {
      if (job.club_member && 'id' in job.club_member) {
        return ClubMemberService.getCurrentMember()?.id === job.club_member.id
      }
      return false
    },
    async deleteJob (job:GroupEventJob):Promise<void> {
      this.$store.commit('toggleLoader')
      const response = await (new GroupEventJobService()).DeleteJob(job)
      if (response) {
        this.$store.commit('addMessage', {
          text: 'Job deleted'
        })
      }
      this.$store.commit('toggleLoader')
    },
    async copyJob (job:GroupEventJob):Promise<void> {
      this.$store.commit('toggleLoader')
      const response = await (new GroupEventJobService()).CopyJob(job)
      if (response) {
        location.reload()
      }
    },
    async takeJob (job:GroupEventJob):Promise<void> {
      this.$store.commit('toggleLoader')
      const response = await (new GroupEventJobService()).takeJob(job)
      if (response) {
        this.job.status = EventInviteStatusTypes.Accepted
        this.job.club_member = response.club_member
        this.$store.commit('addMessage', {
          text: 'Taken task'
        })
      }
      this.$store.commit('toggleLoader')
    }
  }
}
