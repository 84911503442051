
import Dashboard from '@/components/Main/Dashboard.vue'
import GroupService from '@/services/Enitities/GroupService'
import { ClubMemberRights, GroupMemberRights } from '@/models/Enum'
import Authorization from '@/services/App/Authorization'
import GroupJobsOverview from '@/components/Group/GroupJobsOverview.vue'
import ClubLogo from '@/components/Club/ClubLogo.vue'

export default {
  name: 'GroupMembers',
  components: { ClubLogo, GroupJobsOverview, Dashboard },
  data ():unknown {
    return {
      ClubMemberRights,
      GroupMemberRights,
      Authorization,
      group: null
    }
  },
  watch: {
    async $route ():Promise<void> {
      this.$store.commit('toggleLoader')
      this.group = await (new GroupService()).get(this.$route.params.id)
      this.$store.commit('toggleLoader')
    }
  },
  async created ():Promise<void> {
    this.$store.commit('toggleLoader')
    this.group = await (new GroupService()).get(this.$route.params.id)
    this.$store.commit('toggleLoader')
  }
}
