import { Api } from '@/services/API/Api'

export class WebPush {
  static notificationsSupported:boolean = ('Notification' in window && 'serviceWorker' in navigator)
  static notificationsEnabled = false
  serviceWorkerRegistration:ServiceWorkerRegistration|undefined
  static subscription:PushSubscription|undefined

  requestPermission ():void {
    if (!WebPush.notificationsEnabled && WebPush.notificationsSupported) {
      Notification.requestPermission()
        .then(result => {
          if (result === 'granted') {
            this.createSubscription()
              .then(sub => {
                WebPush.subscription = sub
                return (new Api()).request('WebPush', 'POST', {
                  subscription: WebPush.subscription
                })
              })
              .then(() => {
                WebPush.notificationsEnabled = true
              })
          }
        })
    }
  }

  static CheckEnabled ():void {
    (new WebPush()).findSubscription()
      .then(sub => {
        if (sub === null) {
          WebPush.notificationsEnabled = false
        } else {
          WebPush.notificationsEnabled = true
          WebPush.subscription = sub
        }
      })
  }

  deletePermission ():void {
    if (WebPush.subscription !== null) {
      // return axios.delete(`${process.env.VUE_APP_API_ENDPOINT}/push/v1`, {
      //   endpoint: this.subscription.endpoint
      // })
      //   // unsubscribe on the client
      //   .then(() => this.subscription.unsubscribe())
      //   .then(() => {
      //     // update the data
      //     this.notificationsEnabled = false
      //     this.buttonDisabled = false
      //     this.subscription = null
      //   })
    }
  }

  createSubscription (): Promise<PushSubscription> {
    return navigator.serviceWorker.ready // returns a Promise, the active SW registration
      .then(swreg => {
        this.serviceWorkerRegistration = swreg
        return this.subscribe(this.serviceWorkerRegistration)
      })
  }

  getSubscription (swreg:ServiceWorkerRegistration) : Promise<PushSubscription | null> {
    return swreg.pushManager.getSubscription()
  }

  subscribe (swreg:ServiceWorkerRegistration): Promise<PushSubscription> {
    const vapidPublicKey = process.env.VUE_APP_VAPID_PUBLIC_KEY
    const convertedVapidPublicKey = this.urlBase64ToUint8Array(vapidPublicKey)
    return swreg.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: convertedVapidPublicKey
    })
  }

  findSubscription (): Promise<PushSubscription | null> {
    return navigator.serviceWorker.ready
      .then(swreg => {
        this.serviceWorkerRegistration = swreg
        return this.getSubscription(this.serviceWorkerRegistration)
      })
  }

  urlBase64ToUint8Array (base64String:string): Uint8Array {
    const padding = '='.repeat((4 - base64String.length % 4) % 4)
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/')
    const rawData = window.atob(base64)
    const outputArray = new Uint8Array(rawData.length)
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
  }
}
