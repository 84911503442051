
import Dashboard from '@/components/Main/Dashboard.vue'
import ClubLogo from '@/components/Club/ClubLogo.vue'
import GroupService from '@/services/Enitities/GroupService'
import GroupNewsService from '@/services/Enitities/GroupNewsService'
import moment from 'moment'
import Application from '@/services/App/Application'
import Authentication from '@/services/App/Authentication'
import EditNewsItem from '@/components/News/EditNewsItem.vue'
import Editor from '@/components/Main/Editor.vue'
import { GroupMemberRights } from '@/models/Enum'
import Authorization from '@/services/App/Authorization'
import PublishNewsItem from '@/components/News/PublishNewsItem.vue'
import KMetadata from '@/components/Main/k-metadata.vue'

export default {
  name: 'GroupNewsPost',
  components: { KMetadata, PublishNewsItem, Editor, EditNewsItem, ClubLogo, Dashboard },
  data (): unknown {
    return {
      GroupMemberRights,
      Authorization,
      moment,
      group: null,
      editMode: false,
      tab: null,
      loading: false,
      saving: false,
      user: Authentication.getUser(),
      isDarkMode: Application.IsDarkMode(),
      blocks: [],
      newsPost: {}
    }
  },
  watch: {
    async $route (): Promise<void> {
      await this.load()
    }
  },
  async created (): Promise<void> {
    await this.load()
  },
  methods: {
    async load (): Promise<void> {
      this.loading = true
      this.$store.commit('toggleLoader')
      this.group = await (new GroupService()).get(this.$route.params.id)
      this.$store.commit('toggleLoader');
      (new GroupNewsService()).getNewsPost(this.group, this.$route.params.newsId).then((response) => {
        this.newsPost = response
        this.blocks = JSON.parse(this.newsPost.content)
        this.loading = false
      })
      this.editMode = Authorization.HasCurrentGroupMemberRight(GroupMemberRights.GROUP_NEWS, this.group) && this.newsPost.published_at
    },
    onChange (content: unknown): void {
      if (!this.saving) {
        this.saving = true
        this.newsPost.content = content;
        (new GroupNewsService()).updateNews(this.group.id, this.newsPost.id, this.newsPost.title, this.newsPost.description, JSON.stringify(content), this.newsPost.transparency_level)
          .finally(() => {
            this.saving = false
          })
      }
    }

  }
}
