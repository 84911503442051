

import ClubMemberService from '@/services/Enitities/ClubMemberService'

export default {
  name: 'CreateClubMember',
  data ():unknown {
    return {
      dialog: false,
      valid: false,
      loading: false,
      rules: {
        firstname: [
          (v):boolean|string => !!v || this.$t('requiredField')
        ],
        lastname: [
          (v):boolean|string => !!v || this.$t('requiredField')
        ],
        birthdate: [
          (v):boolean|string => !!v || this.$t('requiredField')
        ]
      },
      member: {
        firstname: '',
        lastname: '',
        birthdate: ''
      }
    }
  },
  methods: {
    async createMember (e:Event): Promise<void> {
      e.preventDefault()
      this.$refs.form.validate()
      if (this.valid) {
        this.loading = true
        const response = await (new ClubMemberService()).createClubMember(this.member.firstname, this.member.firstname, this.member.birthdate)
        const data = await response.json()
        await this.$router.push({ name: 'ClubMember', params: { id: data.id } })
      }
      this.loading = false
    }
  }

}
