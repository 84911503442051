import { Api } from '@/services/API/Api'
import { LocalStorage, StorageNames } from '@/services/App/LocalStorage'
import { MemberNotification } from '@/models/MemberNotification'

export class NotificationService {
  api:Api
  constructor () {
    this.api = new Api()
  }

  public static getAllLocalNotifications (): MemberNotification[] {
    const not = LocalStorage.get(StorageNames.Notifications) as MemberNotification[]
    if (not) {
      return not
    }
    return []
  }

  public async notificationIsSeen (notification: MemberNotification):Promise<boolean> {
    if (!notification.is_seen) {
      const response = await this.api.request('notification/' + notification.id + '/seen', 'POST')
      if (response.ok) {
        const content = await response.json()
        LocalStorage.set(StorageNames.Notifications, content)
        return true
      } else {
        return false
      }
    }

    return true
  }

  public async notificationSeenAll ():Promise<boolean> {
    const response = await this.api.request('notification/seenAll', 'POST')
    if (response.ok) {
      const content = await response.json()
      LocalStorage.set(StorageNames.Notifications, content)
      return true
    } else {
      return false
    }
  }
}

export default NotificationService
