import { ClubMemberRights, GroupMemberRights, GroupTypes } from '@/models/Enum'
import ClubMemberService from '@/services/Enitities/ClubMemberService'
import { Group } from '@/models/Group'
import { ClubMember } from '@/models/ClubMember'
import { GroupMember } from '@/models/GroupMember'

export class Authorization {
  public static HasCurrentClubMemberRight (right: ClubMemberRights): boolean {
    const clubMember = ClubMemberService.getCurrentMember()
    if ('rights' in clubMember) {
      return clubMember?.rights?.includes(right)
    }
    return false
  }

  public static IsClubMemberAdmin (clubMember:ClubMember): boolean {
    if ('rights' in clubMember) {
      return clubMember.rights.includes(ClubMemberRights.CLUB_TEAM_MANAGER) ||
        clubMember.rights.includes(ClubMemberRights.CLUB_COMMISSION_MANAGER) ||
        clubMember.rights.includes(ClubMemberRights.CLUB_MANAGER)
    }
    return false
  }

  public static IsGroupMemberAdmin (groupMember:GroupMember): boolean {
    if (groupMember) {
      if (groupMember.rights) {
        return groupMember.rights.includes(GroupMemberRights.GROUP_EVENTS) ||
          groupMember.rights.includes(GroupMemberRights.GROUP_EVENT_JOBS) ||
          groupMember.rights.includes(GroupMemberRights.GROUP_NEWS)
      }
    }
    return false
  }

  public static HasCurrentGroupMemberRight (right:GroupMemberRights, group: Group): boolean {
    const clubMember = ClubMemberService.getCurrentMember()
    if (group === undefined) {
      return false
    }
    if (
      (Authorization.HasCurrentClubMemberRight(ClubMemberRights.CLUB_TEAM_MANAGER) && group.type === GroupTypes.Team) ||
      (Authorization.HasCurrentClubMemberRight(ClubMemberRights.CLUB_COMMISSION_MANAGER) && group.type === GroupTypes.Commission)
    ) {
      return true
    }
    if (!Authorization.HasCurrentClubMemberRight(ClubMemberRights.CLUB_MEMBER)) {
      return false
    }
    if (clubMember && 'group_members' in clubMember) {
      for (const groupMember of clubMember.group_members) {
        if (groupMember.group.id === group.id) {
          return groupMember.rights.includes(right)
        }
      }
    }

    return false
  }

  public static HasClubMemberLoggedIn (): boolean {
    const clubMember = ClubMemberService.getCurrentMember()
    return !!clubMember
  }
}

export default Authorization
