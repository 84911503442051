
import { EventInviteStatusTypes } from '@/models/Enum'
import ClubMemberService from '@/services/Enitities/ClubMemberService'
import GroupEventJobService from '@/services/Enitities/GroupEventJobService'
import ClubMemberStatusSelection from '@/components/Event/ClubMemberStatusSelection.vue'

export default {
  name: 'EventJobSelection',
  components: { ClubMemberStatusSelection },
  props: {
    groupEventJob: {
      required: true
    },
    disabled: {
      default: false,
      required: true
    }
  },
  data ():unknown {
    return {
      EventInviteStatusTypes,
      loading: false
    }
  },
  watch: {
    groupEventJob: {
      async handler (): Promise<void> {
        this.loading = true
        if (this.groupEventJob.status === EventInviteStatusTypes.Accepted) {
          await this.acceptJob()
        } else if (this.groupEventJob.status === EventInviteStatusTypes.Declined) {
          await this.declineJob()
        }
        this.loading = false
      },
      deep: true
    }
  },
  methods: {
    JobIsForCurrentMember () :boolean {
      if ('club_member' in this.groupEventJob) {
        return ClubMemberService.getCurrentMember()?.id === this.groupEventJob.club_member.id
      }
      return false
    },
    async acceptJob ():Promise<void> {
      await (new GroupEventJobService()).AcceptJob(this.groupEventJob)
      this.groupEventJob.status = EventInviteStatusTypes.Accepted
      this.$store.commit('addMessage', {
        text: 'Status changed'
      })
    },
    async declineJob ():Promise<void> {
      await (new GroupEventJobService()).DeclineJob(this.groupEventJob)
      this.groupEventJob.status = EventInviteStatusTypes.Declined
      this.$store.commit('addMessage', {
        text: 'Status changed'
      })
    }
  }
}
