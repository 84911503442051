
import ClubMemberService from '@/services/Enitities/ClubMemberService'
import moment from 'moment'

export default {
  name: 'MemberDetails',
  props: {
    clubMember: {
      required: true
    },
    canEdit: {
      required: true,
      default: false
    }
  },
  data ():unknown {
    return {
      moment,
      birtdate: '',
      dialog: false
    }
  },
  created ():void {
    this.birtdate = moment(this.clubMember.birthdate).format('YYYY-MM-DD')
  },
  methods: {
    async saveDetails ():Promise<void> {
      if (this.canEdit) {
        this.$store.commit('toggleLoader')
        this.clubMember.birthdate = this.birtdate
        await (new ClubMemberService()).updateMember(this.clubMember.id, this.clubMember)
        this.$store.commit('addMessage', {
          text: 'Member changed'
        })
        this.$store.commit('toggleLoader')
      }
    }
  }
}
