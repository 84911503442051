
import ClubService from '@/services/Enitities/ClubService'
import moment from 'moment'
import { EventTypes } from '@/models/Enum'
import EventCard from '@/components/Event/EventDialog/EventCard.vue'
export default {
  name: 'ClubCalendar',
  components: { EventCard },
  data ():unknown {
    return {
      moment,
      value: '',
      EventTypes,
      loadedEvents: [],
      events: [],
      eventTypeFilter: [EventTypes.Game, EventTypes.Other, EventTypes.Practice],
      eventGroupFilter: [],
      eventGroups: [],
      loading: false,
      weekNr: moment().week(),
      club: ClubService.get()
    }
  },
  methods: {
    loadGroups ():void {
      this.eventGroupFilter = []
      this.eventGroups = []
      const Groups = ClubService.get().groups.filter(g => !g.deleted_at)
      const GroupsOptions = this.eventGroups
      const GroupsSelected = this.eventGroupFilter
      Groups.forEach(function (Group) {
        GroupsOptions.push({
          text: Group.name,
          value: Group.id
        })
        GroupsSelected.push(Group.id)
      })
      this.eventGroupFilter = GroupsSelected
      this.eventGroups = GroupsOptions
    },
    async getEvents ():Promise<void> {
      this.loading = true
      this.events = []
      this.loadedEvents = []
      const week = moment().week(this.weekNr)
      const monday = week.day(1).format('YYYY-MM-DDT00:00')
      const sunday = week.day(7).format('YYYY-MM-DDT23:59')
      const response = await (new ClubService()).getClubEvents(monday, sunday)
      this.loading = false
      if (response.ok) {
        const events = await response.json()
        this.loadedEvents = events
        this.events = events
        this.filter()
      }
    },
    filter ():void {
      this.events = this.loadedEvents
      this.events = this.events.filter(e => this.eventGroupFilter.includes(e.group.id))
      this.events = this.events.filter(e => this.eventTypeFilter.includes(e.type))
    },
    resetFilter ():void {
      this.eventTypeFilter = [EventTypes.Practice, EventTypes.Game, EventTypes.Other]
      this.loadGroups()
      this.filter()
    },
    prev ():void {
      this.weekNr--
      this.getEvents()
    },
    next ():void {
      this.weekNr++
      this.getEvents()
    }
  },
  mounted ():void {
    this.loadGroups()
    this.getEvents()
  }
}
