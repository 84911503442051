
import GroupEventService from '@/services/Enitities/GroupEventService'
import moment from 'moment'
import CancelEventDialog from '@/components/Event/EventDialog/CancelEventDialog.vue'
import { EventTypes, GroupTypes } from '@/models/Enum'
import DeleteEventDialog from '@/components/Event/EventDialog/DeleteEventDialog.vue'
import Application from '@/services/App/Application'
import Authentication from '@/services/App/Authentication'

export default {
  name: 'EventEdit',
  components: { DeleteEventDialog, CancelEventDialog },
  props: {
    groupEvent: {
      required: true
    }
  },
  created ():void{
    this.event.start = moment(this.groupEvent.start).format('YYYY-MM-DDTHH:mm')
    this.event.end = moment(this.groupEvent.end).format('YYYY-MM-DDTHH:mm')
    this.event.gatheringTime = moment(this.groupEvent.gathering_time).format('HH:mm')
    this.event.duration = moment.duration(moment(this.groupEvent.end).diff(moment(this.groupEvent.start))).asHours()
  },
  methods: {
    validate ():void {
      this.$refs.form.validate()
    },
    async saveEvent (e:Event):Promise<void> {
      e.preventDefault()
      this.validate()
      if (this.valid) {
        this.$store.commit('toggleLoader')
        await (new GroupEventService()).UpdateEvent({
          id: this.groupEvent.id,
          title: this.groupEvent.title,
          start: this.event.start,
          duration: this.event.duration,
          location: this.groupEvent.location,
          type: this.groupEvent.type,
          description: this.groupEvent.description,
          gathering_location: this.groupEvent.gathering_location,
          gathering_time: this.event.gatheringTime.includes(':') ? this.event.gatheringTime : null,
          private: this.groupEvent.private,
          is_club_event: this.groupEvent.is_club_event,
          has_open_invitation: this.groupEvent.has_open_invitation
        })
        this.$store.commit('addMessage', {
          text: 'Event changed'
        })
        this.$store.commit('toggleLoader')
      }
    }
  },
  data ():unknown {
    return {
      EventTypes,
      GroupTypes,
      valid: false,
      dialog: false,
      user: Authentication.getUser(),
      isDarkMode: Application.IsDarkMode(),
      moment,
      event: {
        titleRules: [
          (v): boolean | string => !!v || this.$t('requiredField'),
          (v): boolean | string => v.length < 255 || 'Title can only be 255 characters'
        ],
        typeRules: [
          (v): boolean | string => !!v || this.$t('requiredField')
        ],
        start: '',
        startRules: [
          (v):boolean|string => !!v || this.$t('requiredField')
        ],
        end: '',
        duration: 0,
        durationRules: [
          (v):boolean|string => !!v || this.$t('requiredField'),
          (v):boolean|string => parseFloat(v) > 0 || 'Duration needs to be more then zero'
        ],
        locationRules: [
          (v):boolean|string => !!v || this.$t('requiredField'),
          (v):boolean|string => v.length < 255 || 'Title can only be 255 characters'
        ],
        gatheringTime: '',
        gatheringTimeRules: [
          (v):boolean|string => v < this.event.start.slice(11, 99) || 'Gathering time needs to before start time'
        ],
        gatheringLocationRules: [
          (v):boolean|string => (v.length < 255 && v.length >= 0) || 'Gathering location can only be 255 characters'
        ]
      }
    }
  }
}
