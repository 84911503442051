
export class Http {
    url:string

    body:Record<string, unknown>

    method:string

    headers:Headers

    constructor (url:string, body = {}, method:string, headers?:Headers) {
      this.url = url
      this.body = body
      this.method = method
      this.headers = headers
    }

    public async send (): Promise<Response> {
      let body = null
      if (this.method.toUpperCase() === 'GET') {
        const queryString = Object.keys(this.body).map(key => key + '=' + this.body[key]).join('&')
        this.url += '?' + queryString
      }

      if (this.method.toUpperCase() === 'POST') {
        this.headers.set('Content-Type', 'application/json')
        body = JSON.stringify(this.body)
      }

      return await fetch(this.url, {
        headers: this.headers,
        method: this.method, // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'omit', // include, *same-origin, omit
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: body // body data type must match "Content-Type" header
      })
    }
}
