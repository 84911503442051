
import Dashboard from '@/components/Main/Dashboard.vue'
import ClubMemberService from '@/services/Enitities/ClubMemberService'
import UserService from '@/services/Enitities/UserService'
import { ClubMemberRights } from '@/models/Enum'
import Authorization from '@/services/App/Authorization'
import EventCard from '@/components/Event/EventDialog/EventCard.vue'
import ParentSwitch from '@/components/Parent/ParentSwitch.vue'
import ClubService from '@/services/Enitities/ClubService'
import ClubMemberRegistry from '@/components/Security/Registration/ClubMemberRegistry.vue'
import Authentication from '@/services/App/Authentication'
import { LocalStorage, StorageNames } from '@/services/App/LocalStorage'
import ClubCalendar from '@/components/Club/ClubCalendar.vue'
import { onTimeDialog, onTimeDialogs } from '@/services/App/onTimeDialog'
import TasksListItem from '@/components/Overvieuw/TasksListItem.vue'
import ClubEventsListItem from '@/components/Overvieuw/ClubEventsListItem.vue'
import ClubNewsListItem from '@/components/Overvieuw/ClubNewsListItem.vue'
import LatestNotification from '@/components/Main/Dashboard/LatestNotification.vue'

export default {
  name: 'Overview',
  components: { LatestNotification, ClubNewsListItem, ClubEventsListItem, TasksListItem, ClubCalendar, ClubMemberRegistry, ParentSwitch, EventCard, Dashboard },
  data ():unknown {
    return {
      onTimeDialog,
      onTimeDialogs,
      myEvents: [],
      clubEvents: [],
      myJobs: [],
      loading: false,
      loadingJobs: false,
      clubMember: ClubMemberService.getCurrentMember(),
      clubMembers: LocalStorage.get(StorageNames.Members) as [],
      Authorization,
      ClubMemberRights,
      Authentication,
      loadedEvents: 3,
      newsCount: 0,
      club: ClubService.get()
    }
  },
  methods: {
    async getOverview ():Promise<void> {
      this.loading = true
      const result = await UserService.getOverview()
      if (result.ok) {
        const response = await result.json()
        this.myJobs = JSON.parse(response.jobs)
        this.myEvents = JSON.parse(response.events)
        this.clubEvents = response.clubEvents
        this.newsCount = response.newsCount
      }
      this.loading = false
    },
    async showMoreEvents (): Promise<void> {
      this.loading = true
      this.loadedEvents += 3
      const result = await UserService.getUserEvents(this.loadedEvents)
      if (result.ok) {
        this.myEvents = await result.json()
      }
      this.loading = false
    }
  },
  created ():void {
    if ('id' in this.clubMember) {
      this.getOverview()
    }
  }
}
