
import ClubService from '@/services/Enitities/ClubService'
import UserService from '@/services/Enitities/UserService'

export default {
  name: 'ResetPasswordDialog',
  data ():unknown {
    return {
      dialog: false,
      valid: false,
      club: {},
      password: '',
      passwordRules: [
        (v):boolean|string => !!v || this.$t('requiredField'),
        (v):boolean|string => v.length >= 8 || 'The password needs at least 8 characters'
      ],
      password2: '',
      password2Rules: [
        (v):boolean|string => !!v || this.$t('requiredField'),
        (v):boolean|string => v.length >= 8 || 'The password needs at least 8 characters',
        (v):boolean|string => v === this.password || 'The passwords don\'t match'
      ]
    }
  },
  async created ():Promise<void> {
    this.club = ClubService.get()
    const params = new URLSearchParams(window.location.search)
    if (params.has('reset')) {
      this.dialog = true
    }
  },
  methods: {
    validate ():void {
      this.$refs.form.validate()
    },
    async request (e:Event):Promise<void> {
      this.$store.commit('toggleLoader')
      e.preventDefault()
      const params = new URLSearchParams(window.location.search)
      const response = await UserService.passwordReset(params.get('reset'), this.password, this.password2)
      if (response.ok) {
        this.dialog = false
        location.replace('/')
      }
      this.$store.commit('toggleLoader')
    }
  }
}
