
import Dashboard from '@/components/Main/Dashboard.vue'
import ClubLogo from '@/components/Club/ClubLogo.vue'
import MemberOverview from '@/components/Club/Member/MemberOverview.vue'
import CreateClubMember from '@/components/Member/CreateClubMember.vue'

export default {
  name: 'Members',
  components: { CreateClubMember, MemberOverview, ClubLogo, Dashboard }
}
