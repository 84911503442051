
import NotificationService from '@/services/Enitities/NotificationService'
import { MemberNotification } from '@/models/MemberNotification'
import ClubMemberService from '@/services/Enitities/ClubMemberService'
import WebPushCard from '@/components/WebPush/WebPushCard.vue'
import moment from 'moment'

export default {
  name: 'NotificationWindow',
  components: { WebPushCard },
  data (): unknown {
    return {
      NotificationService,
      moment,
      selectedMember: ClubMemberService.getCurrentMember()
    }
  },
  methods: {
    async goTo (notification: MemberNotification):Promise<void> {
      const response = await (new NotificationService()).notificationIsSeen(notification)
      if (response) {
        this.$store.commit('setNotifications', NotificationService.getAllLocalNotifications())
        if (this.selectedMember.id !== notification.club_member.id) {
          ClubMemberService.setCurrentMember(notification.club_member)
        }
        await this.$router.push(notification.url)
      }
    },
    async seenAll (): Promise<void> {
      const response = await (new NotificationService()).notificationSeenAll()
      if (response) {
        this.$store.commit('setNotifications', NotificationService.getAllLocalNotifications())
      }
    }
  }
}
