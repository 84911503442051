import Login from '@/views/Security/Login.vue'
import TermsOfService from '@/views/terms/termsOfService.vue'
import Privacypolicy from '@/views/terms/Privacypolicy.vue'

export default [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/',
    redirect: '/dashboard/main',
    name: 'HomePage'
  },
  {
    path: '*',
    redirect: '/dashboard/main',
    name: 'NotFound'
  },
  {
    path: '/termsofservice',
    component: TermsOfService,
    name: 'termsOfService'
  },
  {
    path: '/privacypolicy',
    component: Privacypolicy,
    name: 'Privacypolicy'
  }
]
