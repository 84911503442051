
import Dashboard from '@/components/Main/Dashboard.vue'
import ClubService from '@/services/Enitities/ClubService'
import ClubLogo from '@/components/Club/ClubLogo.vue'
export default {
  name: 'Settings',
  components: { ClubLogo, Dashboard },
  data ():unknown {
    return {
      club: {}
    }
  },
  created ():void {
    this.club = ClubService.get()
  },
  methods: {
    async saveSettings (e:Event):Promise<void> {
      this.$store.commit('toggleLoader')
      e.preventDefault()
      await (new ClubService()).updateClub(this.club)
      this.$store.commit('toggleLoader')
    }
  }
}
