
import GroupEventService from '@/services/Enitities/GroupEventService'

export default {
  name: 'CancelEventDialog',
  data ():unknown {
    return {
      valid: false,
      dialog: false,
      loading: false,
      cancelReason: '',
      reasonRules: [
        (v):boolean|string => !!v || this.$t('requiredField')
      ]
    }
  },
  props: {
    groupEvent: {
      required: true
    }
  },
  methods: {
    validate ():void {
      this.$refs.form.validate()
    },
    async cancelEvent (e:Event):Promise<void> {
      e.preventDefault()
      this.validate()
      if (this.valid) {
        this.loading = true
        const response = await (new GroupEventService()).CancelEvent(this.groupEvent, this.cancelReason)
        if (response) {
          this.groupEvent.cancel_reason = this.cancelReason
          this.groupEvent.canceled = true
          this.dialog = false
        }
        this.loading = false
      }
    }
  }
}
