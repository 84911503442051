import { LocalStorage, StorageNames } from '@/services/App/LocalStorage'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Cookies from 'js-cookie'
import Authentication from '@/services/App/Authentication'
import { Api } from '@/services/API/Api'
import { User } from '@/models/User'

class UserService {
  public static async logout ():Promise<boolean> {
    if (await Authentication.IsAuthenticated()) {
      const Auth = Cookies.get('Device_AUTH')
      if (Auth) {
        const response = await (new Api()).request('user/logout', 'POST')
        if (response.ok) {
          LocalStorage.delete(StorageNames.User)
          LocalStorage.delete(StorageNames.SelectedMember)
          LocalStorage.delete(StorageNames.Notifications)
          LocalStorage.delete(StorageNames.Members)
          Cookies.remove('Device_AUTH')
          return true
        }
      }
    }
    return false
  }

  public static registerUser (user = {}): Promise<Response> {
    return (new Api()).request('registration/new', 'POST', user)
  }

  public static registerChild (user = {}): Promise<Response> {
    return (new Api()).request('registration/child', 'POST', user)
  }

  public static registerClubMember (user = {}): Promise<Response> {
    return (new Api()).request('registration/clubMember', 'POST', user)
  }

  public static validateToken (token:string): Promise<Response> {
    return (new Api()).request('registration/validate/' + token, 'POST')
  }

  public static getOverview (): Promise<Response> {
    return (new Api()).request('user/overview')
  }

  public static getUserEvents (limit:number): Promise<Response> {
    return (new Api()).request('user/upcoming', 'GET', {
      limit: limit
    })
  }

  public static getUserNews (): Promise<Response> {
    return (new Api()).request('user/news')
  }

  public static requestPasswordReset (email:string): Promise<Response> {
    return (new Api()).request('reset-password', 'POST', {
      email: email
    })
  }

  public static updateSetting (setting: string, value:string): Promise<Response> {
    return (new Api()).request('user/setting', 'POST', {
      setting: setting,
      value: value
    })
  }

  public static passwordReset (token:string, password1:string, password2:string): Promise<Response> {
    return (new Api()).request('reset-password/reset/' + token, 'POST', {
      password: password1,
      password_validate: password2
    })
  }

  public static updatePersonalInformation (firstname:string, lastname:string, email:string, password: string, passwordValidate: string): Promise<Response> {
    return (new Api()).request('user/update', 'POST', {
      firstname: firstname,
      lastname: lastname,
      email: email,
      password: password,
      password_validate: passwordValidate
    })
  }

  public static async searchUser (search:string, offset = 0, limit = 12):Promise<{amount:string, result:User[]} | undefined> {
    const result = await (new Api()).request('user/search', 'GET', {
      search: search,
      limit: limit,
      offset: offset
    })
    if (result.ok) {
      return await result.json()
    }
  }
}

export default UserService
