
import ClubMemberService from '@/services/Enitities/ClubMemberService'
import { ClubMemberRights } from '@/models/Enum'

export default {
  name: 'MemberGuest',
  props: {
    clubMember: {
      required: true
    }
  },
  data ():unknown {
    return {
      dialog: false
    }
  },
  methods: {
    async moveGuestToMember ():Promise<void> {
      this.$store.commit('toggleLoader')
      const response = await (new ClubMemberService()).makeMember(this.clubMember)
      if (response) {
        this.clubMember.rights.push(ClubMemberRights.CLUB_MEMBER)
      }
      this.$store.commit('toggleLoader')
    }
  }
}
