
import ClubMemberService from '@/services/Enitities/ClubMemberService'
import SearchUser from '@/components/User/SearchUser.vue'

export default {
  name: 'MemberParents',
  components: { SearchUser },
  data ():unknown {
    return {
      dialog: false,
      relations: [],
      members: [],
      selectedMember: null,
      searchingMember: false,
      search: null
    }
  },
  props: {
    clubMember: {
      required: true
    }
  },
  methods: {
    async removeConnectedMember (relationId:string):Promise<void> {
      this.relations = await (new ClubMemberService()).removeConnectedMember(this.clubMember, relationId)
    },
    async addConnectedMember ():Promise<void> {
      this.relations = await (new ClubMemberService()).addConnectedMember(this.clubMember, this.selectedMember)
      this.type = null
      this.selectedMember = null
    }
  },
  mounted ():void {
    this.relations = this.clubMember.parents
  }
}
