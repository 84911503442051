
import EventJobsItem from '@/components/Event/EventJobs/EventJobsItem.vue'
import MainActionCard from '@/components/Main/MainActionCard.vue'

export default {
  name: 'TasksListItem',
  components: { MainActionCard, EventJobsItem },
  props: {
    tasks: {
      type: Array
    }
  }
}
