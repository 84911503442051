
import { EventInviteStatusTypes } from '@/models/Enum'

export default {
  name: 'ClubMemberStatusSelection',
  props: {
    value: {
      type: Number
    },
    reason: {
      type: String,
      required: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    canEdit: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  methods: {
    changeStatus (status:number):void {
      this.$emit('input', status)
    }
  },
  data ():unknown {
    return {
      EventInviteStatusTypes,
      statuses: {
        [EventInviteStatusTypes.Pending]: {
          icon: 'mdi-alarm',
          color: 'grey'
        },
        [EventInviteStatusTypes.Declined]: {
          icon: 'mdi-close-thick',
          color: 'red'
        },
        [EventInviteStatusTypes.Accepted]: {
          icon: 'mdi-check-bold',
          color: 'green'
        }
      }
    }
  }
}
