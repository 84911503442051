
import { GroupTypes } from '@/models/Enum'

export default {
  name: 'CurrentGroupList',
  props: {
    groupMembers: {
      required: true
    }
  },
  created ():void {
    this.list = this.groupMembers.sort((a, b) => (a.group.name > b.group.name) ? 1 : -1)
  },
  data ():unknown {
    console.log(this.groupMembers)
    return {
      list: [],
      GroupTypes
    }
  }
}
