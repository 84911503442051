
import ClubMemberService from '@/services/Enitities/ClubMemberService'
import { ClubMember } from '@/models/ClubMember'
import { LocalStorage, StorageNames } from '@/services/App/LocalStorage'
import Authentication from '@/services/App/Authentication'

export default {
  name: 'ParentSwitch',
  data ():unknown {
    return {
      selectedMember: ClubMemberService.getCurrentMember(),
      members: LocalStorage.get(StorageNames.Members) as [],
      user: Authentication.getUser(),
      userIsAClubMember: false
    }
  },
  created ():void {
    this.userIsAClubMember = this.members.find((member) => { return member?.user?.id === this.user.id }) !== undefined
  },
  watch: {
    selectedMember (value:ClubMember):void {
      if (value === this.selectedMember) {
        ClubMemberService.setCurrentMember(value)
        this.$store.commit('toggleLoader')
        window.location.reload()
      }
    }
  }
}
