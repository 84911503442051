
import ClubService from '@/services/Enitities/ClubService'
import Application from '@/services/App/Application'

export default {
  name: 'Loader',
  data ():unknown {
    return {
      club: {},
      logoImage: ''
    }
  },
  created ():void {
    this.club = ClubService.get()
    this.logoImage = Application.getApiUrl() + '/resource/' + this.club.id + '/logo.png'
  }
}
