
import Dashboard from '../../components/Main/Dashboard.vue'
import ClubService from '../../services/Enitities/ClubService'
import Authorization from '@/services/App/Authorization'
import { ClubMemberRights } from '@/models/Enum'
import MainActionCard from '@/components/Main/MainActionCard.vue'
import Authentication from '@/services/App/Authentication'
import ClubMemberService from '@/services/Enitities/ClubMemberService'
import Application from '@/services/App/Application'
import NewsCard from '@/components/News/NewsCard.vue'

export default {
  name: 'ClubDashboard',
  components: { NewsCard, MainActionCard, Dashboard },
  methods: {
    async loadNews (): Promise<void> {
      const response = await (new ClubService()).getClubNews()
      this.news = await response.json()
      this.loading = false
    }
  },
  data (): unknown {
    return {
      club: ClubService.get(),
      news: [],
      loading: true,
      Application,
      Authorization,
      Authentication,
      ClubMemberService,
      ClubMemberRights
    }
  }
}
