
import { GroupMemberTypes, GroupTypes } from '@/models/Enum'
import ClubMemberService from '@/services/Enitities/ClubMemberService'
import MemberCard from '@/components/Member/MemberCard.vue'
import { ClubMember } from '@/models/ClubMember'

export default {
  name: 'ClubMemberDuplicatesTables',
  components: { MemberCard },
  data ():unknown {
    return {
      GroupMemberTypes,
      GroupTypes,
      members: [],
      loading: false,
      headers: [
        {
          text: this.$t('clubMember.firstname') + ' ' + this.$t('clubMember.lastname').toLowerCase(),
          value: 'profile',
          sortable: false
        },
        {
          text: 'Account',
          value: 'account',
          sortable: false
        },
        {
          text: 'Parents',
          value: 'parents',
          sortable: false
        },
        {
          text: 'Age',
          value: 'age',
          sortable: false
        },
        {
          text: this.$t('group.groups'),
          value: 'groups',
          sortable: false
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  methods: {
    async loadMembers () :Promise<void> {
      this.loading = true
      this.members = []
      const response = await (new ClubMemberService()).duplicateMembers()
      if (response.ok) {
        this.members = await response.json()
      }

      this.loading = false
    },
    async deleteMember (member: ClubMember):Promise<void> {
      this.loading = true
      await (new ClubMemberService()).removeMember(member)
      await this.loadMembers()
      this.loading = false
    }
  },
  mounted ():void {
    this.loadMembers()
  }
}
