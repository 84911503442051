
import EditorJS from '@editorjs/editorjs'
import Header from '@editorjs/header'
import Table from '@editorjs/table'
import Embed from '@editorjs/embed'
import Marker from '@editorjs/marker'
import Delimiter from '@editorjs/delimiter'
import SimpleImage from 'editorjs-inline-image'
import Button from 'editorjs-button'

import ClubService from '@/services/Enitities/ClubService'

export default {
  name: 'Editor',
  data (): unknown {
    return {
      editor: null,
      timeout: null,
      club: ClubService.get(),
      themeColor: ClubService.get().theme_color,
      changes: false
    }
  },
  props: {
    readonly: {
      default: true
    },
    content: {
      required: true
    },
    loading: {
      default: false
    }
  },
  created (): void {
    console.log(this.content.data ? this.content : null)
    this.editor = new EditorJS({
      holder: 'editorjs',
      readOnly: this.readonly,
      placeholder: '',
      tools: {
        header: Header,
        Marker: Marker,
        Embed: Embed,
        Delimiter: Delimiter,
        Image: SimpleImage,
        Button: Button,
        Table: Table
      },
      data: this.content,
      onChange: (): void => {
        if (this.loading || this.content === null) return

        clearTimeout(this.timeout)
        this.changes = true

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        var self = this
        this.timeout = setTimeout(async function () {
          self.editor.save().then((outputData) => {
            self.$emit('change', outputData)
            self.changes = false
          })
        }, 2000)
      }
    })
  },
  watch: {
    readonly (): void {
      this.editor.readOnly.toggle()
    }
  }
}
