import ClubSettings from '@/views/Club/Settings.vue'
import { ClubMemberRights } from '@/models/Enum'
import members from '@/views/Club/Members/Members.vue'
import ClubMember from '@/views/Club/Members/Member.vue'
import Duplicates from '@/views/Club/Members/Duplicates.vue'
import groupDashboard from '@/views/Group/Group.vue'
import groupEvent from '@/views/Group/GroupEvent.vue'
import commissions from '@/views/Group/Commissions.vue'
import teams from '@/views/Group/Teams.vue'
import CalendarOverview from '@/views/Calendar/CalendarOverview.vue'
import Dashboard from '@/views/User/Overview.vue'
import Profile from '@/views/User/Profile.vue'
import ClubDashboard from '@/views/Club/ClubDashboard.vue'
import groupEvents from '@/views/Group/GroupEvents.vue'
import groupMembers from '@/views/Group/GroupMembers.vue'
import GroupOpenTasks from '@/views/Group/GroupOpenTasks.vue'
import ClubMembers from '@/views/Club/Members/ClubMembers.vue'
import Guests from '@/views/Club/Members/Guests.vue'
import groupNews from '@/views/Group/GroupNews.vue'
import groupNewsPost from '@/views/Group/GroupNewsPost.vue'

export default [
  {
    path: '/dashboard/main',
    component: Dashboard,
    name: 'Dashboard'
  },
  {
    path: '/dashboard/profile',
    component: Profile,
    name: 'Profile'
  },
  {
    path: '/dashboard/club/settings',
    name: 'ClubSettings',
    component: ClubSettings,
    meta: {
      clubRight: ClubMemberRights.CLUB_MANAGER
    }
  },
  {
    path: '/dashboard/club/members',
    name: 'Members',
    component: members,
    meta: {
      clubRight: ClubMemberRights.CLUB_MEMBER_MANAGER
    }
  },
  {
    path: '/dashboard/club/members/guests',
    name: 'Guests',
    component: Guests,
    meta: {
      clubRight: ClubMemberRights.CLUB_MEMBER_MANAGER
    }
  },
  {
    path: '/dashboard/club/members/duplicates',
    name: 'MemberDuplicates',
    component: Duplicates,
    meta: {
      clubRight: ClubMemberRights.CLUB_MEMBER_MANAGER
    }
  },
  {
    path: '/dashboard/club/members/clubMembers',
    name: 'ClubMembers',
    component: ClubMembers,
    meta: {
      clubRight: ClubMemberRights.CLUB_MEMBER_MANAGER
    }
  },
  {
    path: '/dashboard/club/member/:id',
    name: 'ClubMember',
    component: ClubMember,
    meta: {
      clubRight: ClubMemberRights.CLUB_MEMBER_MANAGER
    }
  },
  {
    path: '/dashboard/club',
    name: 'ClubDashboard',
    component: ClubDashboard
  },
  {
    path: '/dashboard/group/:id',
    component: groupDashboard,
    name: 'Group',
    meta: {}
  },
  {
    path: '/dashboard/group/:id/events',
    component: groupEvents,
    name: 'GroupEvents',
    meta: {}
  },
  {
    path: '/dashboard/group/:id/members',
    component: groupMembers,
    name: 'GroupMembers',
    meta: {}
  },
  {
    path: '/dashboard/group/:id/news',
    component: groupNews,
    name: 'GroupNews',
    meta: {}
  },
  {
    path: '/dashboard/group/:id/news/:newsId',
    component: groupNewsPost,
    name: 'GroupNewsPost',
    meta: {}
  },
  {
    path: '/dashboard/group/:id/openTasks',
    component: GroupOpenTasks,
    name: 'GroupOpenTasks',
    meta: {}
  },
  {
    path: '/dashboard/groupEvent/:id',
    component: groupEvent,
    name: 'GroupEvent',
    meta: {}
  },
  {
    path: '/dashboard/teams',
    component: teams,
    name: 'Teams',
    meta: {}
  },
  {
    path: '/dashboard/commissions',
    component: commissions,
    name: 'Commissions',
    meta: {}
  },
  {
    path: '/dashboard/calendar',
    component: CalendarOverview,
    name: 'Calendar',
    meta: {}
  }
]
