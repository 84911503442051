
import UserService from '@/services/Enitities/UserService'
import Authentication from '@/services/App/Authentication'
import moment from 'moment'

export default {
  name: 'ProfileCard',
  created ():void {
    this.email = this.user.email
    this.firstname = this.user.first_name
    this.lastname = this.user.last_name
  },
  methods: {
    async logoutUser ():Promise<void> {
      this.$store.commit('toggleLoader')
      const result = await UserService.logout()
      this.$store.commit('toggleLoader')
      if (result) {
        await this.$router.push({ name: 'login' })
      }
    },
    async saveDetails ():Promise<void> {
      this.isLoadingSave = true
      const response = await UserService.updatePersonalInformation(this.firstname, this.lastname, this.email, this.password, this.password2)
      if (response.ok) {
        const result = await response.json()
        Authentication.setUser(result)
      }
      this.isLoadingSave = false
    }
  },
  data ():unknown {
    return {
      moment,
      valid: false,
      isLoadingSave: false,
      user: Authentication.getUser(),
      email: '',
      emailRules: [
        (v):boolean|string => !!v || this.$t('requiredField'),
        (v):boolean|string => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      firstname: '',
      firstnameRules: [
        (v):boolean|string => !!v || this.$t('requiredField'),
        (v):boolean|string => v.length > 2 || 'First name needs to be longer',
        (v):boolean|string => /.[A-z]./.test(v) || 'First name can only have letters'
      ],
      lastname: '',
      lastnameRules: [
        (v):boolean|string => !!v || this.$t('requiredField'),
        (v):boolean|string => v.length > 2 || 'Last name needs to be longer',
        (v):boolean|string => /.[A-z ]./.test(v) || 'Last name can only have letters'
      ],
      password: '',
      passwordRules: [
        (v):boolean|string => (v.length >= 8 || v.length === 0) || 'The password needs at least 8 characters'
      ],
      password2: '',
      password2Rules: [
        (v):boolean|string => (v.length >= 8 || v.length === 0) || 'The password needs at least 8 characters',
        (v):boolean|string => v === this.password || 'The passwords don\'t match'
      ]
    }
  }
}
