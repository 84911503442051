import { LocalStorage, StorageNames } from '@/services/App/LocalStorage'

export enum LocalSettingNames{
  Theme= 'Theme'
}

export class LocalSettings {
  public static set (type:LocalSettingNames, value: string):void {
    let settings = LocalStorage.get(StorageNames.LocalSettings)
    if (settings === false) {
      settings = {}
    }
    settings[type] = value
    LocalStorage.set(StorageNames.LocalSettings, settings)
  }

  public static get (type:LocalSettingNames):string|undefined {
    const settings = LocalStorage.get(StorageNames.LocalSettings)
    if (settings) {
      if (type in settings) {
        return settings[type]
      }
    }
    return undefined
  }
}

export default { LocalSettings, LocalSettingNames }
