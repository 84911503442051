
import NotificationWindow from '@/components/Main/NotificationWindow.vue'
import ClubService from '@/services/Enitities/ClubService'
import ClubMemberService from '@/services/Enitities/ClubMemberService'
import Authentication from '@/services/App/Authentication'

export default {
  name: 'TopBar',
  components: { NotificationWindow },
  async created ():Promise<void> {
    this.club = ClubService.get()
    this.toolbarStyle = {
      'background-color': this.club.background_color
    }
    if (this.clubMember) {
      if (this.clubMember?.initials !== this.user.initials && this.clubMember.initials) {
        this.showActiveM = true
      }
    }
  },
  data ():unknown {
    return {
      club: ClubService.get(),
      showActiveM: false,
      toolbarStyle: {},
      clubMember: ClubMemberService.getCurrentMember(),
      user: Authentication.getUser()
    }
  }
}
