import { render, staticRenderFns } from "./GroupJobsOverview.vue?vue&type=template&id=24733b92&scoped=true&"
import script from "./GroupJobsOverview.vue?vue&type=script&lang=ts&"
export * from "./GroupJobsOverview.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24733b92",
  null
  
)

export default component.exports