
import MainActionCard from '@/components/Main/MainActionCard.vue'
import UserService from '@/services/Enitities/UserService'
import NewsCard from '@/components/News/NewsCard.vue'

export default {
  name: 'ClubNewsListItem',
  components: { NewsCard, MainActionCard },
  props: {
    count: {}
  },
  methods: {
    loadNews ():void {
      UserService.getUserNews().then(async (r) => {
        this.news = await r.json()
        this.loading = false
      })
    }
  },
  data ():unknown {
    return {
      news: [],
      loading: true
    }
  }
}
