<template>
  <v-chip
    small
    label
    class="mt-1 mr-1"
    text-color="grey"
  >
    <v-icon left small>
      {{ icon }}
    </v-icon>
    {{ content }}
  </v-chip>
</template>

<script>
export default {
  name: 'k-metadata',
  props: {
    icon: {
      required: true
    },
    content: {
      required: true
    }
  }
}
</script>

<style scoped>

</style>
