

import UserService from '@/services/Enitities/UserService'

export default {
  name: 'RequestPasswordResetDialog',
  data ():unknown {
    return {
      valid: false,
      dialog: false,
      email: '',
      emailRules: [
        (v):boolean|string => !!v || this.$t('requiredField'),
        (v):boolean|string => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ]
    }
  },
  methods: {
    validate ():void {
      this.$refs.form.validate()
    },
    async request (e:Event):Promise<void> {
      this.$store.commit('toggleLoader')
      e.preventDefault()
      this.validate()
      if (this.valid) {
        const response = await UserService.requestPasswordReset(this.email)
        if (response.ok) {
          this.dialog = false
        }
      }
      this.$store.commit('toggleLoader')
    }
  }
}
