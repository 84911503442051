

import Application from '@/services/App/Application'

export default {
  name: 'MemberAttendance',
  props: {
    clubMember: {
      required: true
    },
    analytics: {
      required: true
    }
  },
  methods: {
    reformatData (data:{[date:string]: number}): unknown {
      const list = []
      Object.entries(data).forEach(function (value) {
        list.push({ x: 'Week ' + value[0].slice(4, 7), y: value[1] })
      })
      return list
    }
  },
  data ():unknown {
    return {
      dialog: false,
      series: [
        {
          name: 'Total',
          data: this.reformatData(this.analytics.graph.total)
        },
        {
          name: 'Accepted',
          data: this.reformatData(this.analytics.graph.accepted)
        },
        {
          name: 'Declined',
          data: this.reformatData(this.analytics.graph.declined)
        },
        {
          name: 'Pending',
          data: this.reformatData(this.analytics.graph.pending)
        }
      ],
      chartOptions: {
        chart: {
          type: 'line'
        },
        colors: ['#555', '#4CAF50', '#F44336', '#9e9e9e'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        theme: {
          mode: Application.IsDarkMode() ? 'dark' : 'light'
        },
        xaxis: {
          type: 'category'
        }
      }
    }
  }
}
