
import EventJobsItem from '@/components/Event/EventJobs/EventJobsItem.vue'
export default {
  name: 'JobReminderDialog',
  components: { EventJobsItem },
  props: {
    groupEventJobs: {
      required: true
    },
    group: {
      required: true
    },
    groupEvent: {
      required: true
    }
  },
  data ():unknown {
    return {
      dialog: this.groupEventJobs.length > 0
    }
  },
  watch: {
    groupEventJobs ():void {
      this.dialog = this.groupEventJobs.length > 0
    }
  }
}
