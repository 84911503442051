import { Api } from '@/services/API/Api'
import { Group } from '@/models/Group'
import { LocalStorage, StorageNames } from '@/services/App/LocalStorage'

export class GroupService {
  api: Api

  constructor () {
    this.api = new Api()
  }

  public async get (id: string): Promise<Group | undefined> {
    const Teams = await this.getAll()
    return Teams.find(t => t.id === id)
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public async getCount (id: string): Promise<object | undefined> {
    const countFetch = await this.api.request('group/' + id)
    return await countFetch.json()
  }

  public async getAll (): Promise<Group[]> {
    const StorageTeams = LocalStorage.get(StorageNames.Teams)
    if (StorageTeams) {
      return StorageTeams as Group[]
    }
    const TeamsFetch = await this.api.request('group/')
    LocalStorage.set(StorageNames.Teams, await TeamsFetch.json())
    return LocalStorage.get(StorageNames.Teams) as Group[]
  }

  public async createGroup (body: Group): Promise<Group> {
    const creatTeamRequest = await this.api.request('group/new', 'POST', body)
    return await creatTeamRequest.json() as Group
  }

  public async updateGroup (body:Group):Promise<Group> {
    const creatTeamRequest = await this.api.request('group/' + body.id, 'POST', body)
    return await creatTeamRequest.json() as Group
  }

  public async deleteGroup (body:Group):Promise<Group> {
    const creatTeamRequest = await this.api.request('group/' + body.id, 'DELETE', body)
    return await creatTeamRequest.json() as Group
  }
}

export default GroupService
