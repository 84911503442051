
import Authentication from '@/services/App/Authentication'
import RequestPasswordResetDialog from '@/components/Security/RequestPasswordResetDialog.vue'
import ResetPasswordDialog from '@/components/Security/ResetPasswordDialog.vue'
import RegistrationDialog from '@/components/Security/Registration/UserRegistrationDialog.vue'

export default {
  name: 'LoginCard',
  components: { RegistrationDialog, ResetPasswordDialog, RequestPasswordResetDialog },
  props: {
    club: {
      required: true
    }
  },
  data ():unknown {
    return {
      valid: false,
      authFailed: false,
      submitted: false,
      email: '',
      emailRules: [
        (v):boolean|string => !!v || this.$t('requiredField'),
        (v):boolean|string => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      password: '',
      passwordRules: [
        (v):boolean|string => !!v || this.$t('requiredField')
      ]
    }
  },
  methods: {
    loginUser: async function (e:Event):Promise<void> {
      e.preventDefault()
      if (this.valid) {
        this.authFailed = false
        this.$store.commit('toggleLoader')
        const auth = new Authentication()
        const response = await auth.loginUser({ email: this.email, password: this.password })
        this.$store.commit('toggleLoader')
        if (response) {
          await this.$router.push({ name: 'Dashboard' })
        } else {
          this.authFailed = true
          this.password = ''
        }
      }
    },
    validate ():void {
      this.$refs.form.validate()
    }
  }
}
