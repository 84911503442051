enum EventInviteStatusTypes {
  Accepted= 1,
  Declined= 2,
  Pending= 0
}

enum EventTypes{
  Game,
  Practice,
  Other
}

enum GroupMemberTypes{
  Player= 0,
  Coach=1,
  Staff=2,
  Chairmen = 3,
  Member = 4
}

enum ClubMemberRights {
 CLUB_MEMBER = 'CLUB_MEMBER',
 CLUB_MANAGER = 'CLUB_MANAGER',
 CLUB_TEAM_MANAGER = 'CLUB_TEAM_MANAGER',
 CLUB_COMMISSION_MANAGER = 'CLUB_COMMISSION_MANAGER',
 CLUB_MEMBER_MANAGER = 'CLUB_MEMBER_MANAGER',
 CLUB_NEWS_POSTS = 'CLUB_NEWS_POSTS',
 CLUB_ANALYTICS = 'CLUB_ANALYTICS',
}

enum GroupMemberRights {
  GROUP_MEMBER = 'GROUP_MEMBER',
  GROUP_EVENTS = 'GROUP_EVENTS',
  GROUP_NEWS = 'GROUP_NEWS',
  GROUP_EVENT_JOBS = 'GROUP_EVENT_JOBS'
}

enum GroupTypes {
  Team = 0,
  Commission = 1
}

enum NewsTransparencyLevels {
  ForGroupMembersOnly = 0,
  ForClubMembersOnly = 1,
  ForRegisteredEmailsAndClubMembersOnly = 2,
  Public = 3
}

export { EventInviteStatusTypes, EventTypes, ClubMemberRights, GroupMemberRights, GroupMemberTypes, GroupTypes, NewsTransparencyLevels }
