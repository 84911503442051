
import GroupEventJobService from '@/services/Enitities/GroupEventJobService'
import EventJobsItem from '@/components/Event/EventJobs/EventJobsItem.vue'

export default {
  name: 'GroupJobsOverview',
  components: { EventJobsItem },
  props: {
    group: {
      required: true
    }
  },
  data ():unknown {
    return {
      loading: false,
      jobs: []
    }
  },
  watch: {
    group (): void {
      this.getJobs()
    }
  },
  methods: {
    async getJobs ():Promise<void> {
      this.loading = true
      this.jobs = await (new GroupEventJobService()).getOpenUpcomingJobs(this.group)
      this.loading = false
    }
  },
  async created ():Promise<void> {
    await this.getJobs()
  }
}
