
import GroupService from '@/services/Enitities/GroupService'
import { ClubMemberRights, GroupMemberRights, GroupTypes } from '@/models/Enum'
import ClubService from '@/services/Enitities/ClubService'
import Authorization from '@/services/App/Authorization'
import { Group } from '@/models/Group'

export default {
  name: 'GroupEventDialog',
  props: {
    group: {
      required: true
    }
  },
  data ():unknown {
    return {
      rights: {
        canEditName: Authorization.HasCurrentClubMemberRight(ClubMemberRights.CLUB_COMMISSION_MANAGER) || Authorization.HasCurrentClubMemberRight(ClubMemberRights.CLUB_TEAM_MANAGER),
        canStyleGroup: true,
        canGiveRights: Authorization.HasCurrentClubMemberRight(ClubMemberRights.CLUB_MANAGER)
      },
      GroupMemberRights,
      ClubMemberRights,
      Authorization,
      valid: false,
      name: this.group.name,
      tab: null,
      groupType: this.group.type,
      canCreatClubEvents: this.group.can_creat_club_events,
      groupTypes: [],
      nameRule: [
        (v):boolean|string => !!v || this.$t('requiredField')
      ]
    }
  },
  methods: {
    validate ():void {
      this.$refs.form.validate()
    },
    async updateGroup (e: Event): Promise<void> {
      this.validate()
      e.preventDefault()
      if (this.valid) {
        this.$store.commit('toggleLoader')
        await (new GroupService()).updateGroup({
          id: this.group.id,
          name: this.name,
          type: this.groupType,
          can_creat_club_events: this.canCreatClubEvents
        })
        await (new ClubService()).Load()
        this.$store.commit('toggleLoader')
      }
    },
    async deleteGroup (group: Group): Promise<void> {
      this.$store.commit('toggleLoader')
      await (new GroupService()).deleteGroup(group)
      this.$store.commit('addMessage', {
        text: 'Member removed from Group'
      })
      this.$store.commit('toggleLoader')
    }
  },
  created ():void {
    if (Authorization.HasCurrentClubMemberRight(ClubMemberRights.CLUB_TEAM_MANAGER)) {
      this.groupTypes.push({ text: this.$t('group.types.team'), value: GroupTypes.Team })
    }
    if (Authorization.HasCurrentClubMemberRight(ClubMemberRights.CLUB_COMMISSION_MANAGER)) {
      this.groupTypes.push({ text: this.$t('group.types.commission'), value: GroupTypes.Commission })
    }
  }
}
