
import GroupNewsService from '@/services/Enitities/GroupNewsService'
import { NewsTransparencyLevels } from '@/models/Enum'

export default {
  name: 'PublishNewsItem',
  data (): unknown {
    return {
      NewsTransparencyLevels,
      dialog: false,
      valid: false
    }
  },
  props: {
    news: {
      required: true
    },
    group: {
      required: true
    }
  },
  methods: {
    publishPost (): void {
      this.$store.commit('toggleLoader');
      (new GroupNewsService()).publishNews(this.group.id, this.news.id, this.news.transparency_level)
        .then((r) => {
          this.news = r
          this.dialog.value = false
        })
        .finally(() => this.$store.commit('toggleLoader'))
    }
  }
}
